import AxiosInterceptor from "../Utils/AxiosInterceptor";

const PREFIX = "access/";

export const getBusinessDetails = async () => {
    return await AxiosInterceptor().get(PREFIX + `getBusinessDetails`);
};

export const updateBusinessDetails = async (payload) => {
    return await AxiosInterceptor().post(
      PREFIX + `updateBusinessDetails`,
      payload
    );
  };
  