import axios from "axios";
import { BASE_URL } from "./Constants";
import { tostE } from "./Toast";
import store from '../Store/store'

const authHeader = () => {
    const authToken = store.getState().user.token;
    if (authToken) {
        return { Authorization: "Bearer " + authToken };
    }
    return {};
};

export default (history = null) => {
    const axiosInstance = axios.create({ baseURL: BASE_URL, headers: authHeader() });

    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response.status === 403 || error.response.status === 401) {
                // do something
                const { message } = error.response.data;
                if (message) {
                    tostE(message)
                } else {
                    tostE("Logging Out!!")
                }
                console.log("NOT FOUND");
                setTimeout(() => {
                    localStorage.clear();
                    if (history) {
                        history.push("/");
                    } else {
                        window.location = "/";
                    }
                }, 1000);
            }
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};
