// CouponCard.js

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Pagination, PaginationItem } from '@mui/material';
import { getAllCouponTemplates } from '../../Services/CoupanServices'; // Adjust the path as per your file structure
import { MEDIA_BASE_URL } from '../../Utils/Constants';
import moment from 'moment';
import { TextLimitShow, formatDate1, getDateForMenu } from '../../Utils/CommonFunctions';

export default function CouponCard({ data }) {
    const location = useLocation();

    const formatDate = (
        slotStartTime,
        duration,
        durationText,
    ) => {
        if (!slotStartTime) return ''; // Add a null check
    
        const [startHour, startMinute] = slotStartTime.split(':').map(Number);
        let startTotalMinutes = startHour * 60 + startMinute;
        if (durationText === 'Hours') {
            duration *= 60;
        }
        let endTotalMinutes = startTotalMinutes + duration;
        let endHour = Math?.floor(endTotalMinutes / 60) % 24;
        let endMinute = endTotalMinutes % 60;
        let formattedStartTime = formatTime(startHour, startMinute);
        let formattedEndTime = formatTime(endHour, endMinute);
    
        return `${formattedStartTime}-${formattedEndTime}`;
    };
    
      
      function formatTime(hour, minute) {
        let amPM = hour >= 12 ? 'PM' : 'AM';
        let formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        let formattedMinute = minute < 10 ? '0' + minute : minute;
        return `${formattedHour}:${formattedMinute}${amPM}`;
      }
    
    const isTemplateDetailPage = location.pathname.includes('/merchant/my-coupon/coupon-template');

    return (
        <div className="col-md-6 coupan-box" key={data.id}>
            <Link to={isTemplateDetailPage ? `/merchant/my-coupon/coupon-template/detail/${data.id}` : `/merchant/my-coupon/published-coupon/detail/${data.id}`} className='coupan-list-box-url'>
                <div className="box-shadow p-4 d-flex cursor">
                    <figure><img src={MEDIA_BASE_URL + data.couponImage} alt='coupon' /></figure>
                    <figcaption>
                        <div  className={`coupan-content-head d-flex align-items-center justify-content-between pb-1 ${isTemplateDetailPage ? 'coupan-temp-box' : null}`}>
                            <h4>{data.title}</h4>
                            <b className="price-text">${data.price}</b>
                        </div>
                        {isTemplateDetailPage ? null : <span className="date-text">
                            <i className="ri-calendar-line"></i>{formatDate1(data?.endDateTime)}{', '}{formatDate(data.slotStartTime, data.duration, data.durationText)}
                         </span>}
                        <span className="discount-text">Discount of {data.disPercentage}%</span>
                         <p>{TextLimitShow(data.description , 20)}</p>
                    </figcaption>
                </div>
            </Link>
        </div>
    );
}
