import React, { useEffect, useState, useRef } from 'react';
import logo from '../../Assets/images/logo.png';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { cleanAllData } from '../../Actions/ActionCreator';
import { Link, useLocation } from 'react-router-dom';
import NotifyIcon from '../../Assets/images/bell.svg';
import { getNotifications } from '../../Services/DashBoardServices';
import UserInitials from '../../Components/Common/UserInitials';
import checkIcon from '../../Assets/images/check-icon.png';
import { Modal, ModalBody } from 'react-bootstrap';

export default function Header() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [notifications, setNotifications] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false); // State for loader
  const [startTime, setStartTime] = useState(null); // Start time of update process
  const [isCollapsed, setIsCollapsed] = useState(true); // State for collapse navbar
  const [showDropdownMenu, setShowDropdownMenu] = useState(false); // State for collapse navbar
  
  const navbarRef = useRef(null);

  const handleLogout = () => {
    setStartTime(new Date()); // Record start time
    setLoading(true); // Show loader before making API call
    setTimeout(() => {
      dispatch(cleanAllData());
      setLoading(false); // Hide loader after API call completes
      //setStartTime(null); // Reset start time
    }, 500);  
  }

  useEffect(() => {
    getNotifications().then((res) => {
      if (res.status === 200) {
        setNotifications(res.data.data)
      }
    }).catch((err) => {
      console.log(err)
    })
  }, [])

  const handleClose = () => {
    setShow(false);
  }

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleNavItemClicked = () => {
    setIsCollapsed(true); // Collapse the navbar when a navigation item is clicked
  };
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsCollapsed(true); // Collapse navbar when clicking outside
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleRemoveClass = () =>{
    let dropdown = document.getElementById("dropdownMenu")
    dropdown.classList.remove("show");

  }

  return (
    <header className="topHeader" id="fixed-header">
      <div className="container">
        {loading}     
        <nav className="navbar navbar-expand-lg " id="navbar-scrollspy" ref={navbarRef}>
          <div className="navbar-inner-box d-flex align-items-center justify-content-between">
            <div className="nav-L d-flex">
              <Link to="/merchant/dashboard" className="navbar-brand desktop-view-logo">
                <img src={logo} alt="Logo" />
              </Link>
            </div>
            <div className="nav-center">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded={!isCollapsed ? 'true' : 'false'}
                aria-label="Toggle navigation"
                onClick={handleToggleCollapse}
              >
                <span className="navbar-toggler-icon">
                  <i className="ri-menu-3-line"></i>
                </span>
              </button>
              <div
                className={'collapse navbar-collapse ' + (isCollapsed ? '' : 'show')}
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link className={"nav-link " + (pathname.includes("dashboard") ? "active" : "")} to="/merchant/dashboard" onClick={handleNavItemClicked}>
                      Dashboard
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className={"nav-link " + (pathname.includes("my-coupon") ? "active" : "")} to="/merchant/my-coupon/published-coupon/list" onClick={handleNavItemClicked}>
                      My Coupons
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className={"nav-link " + (pathname.includes("business-config") ? "active" : "")} to="/merchant/business-config/detail" onClick={handleNavItemClicked}>
                      Business Configuration
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className={"nav-link " + (pathname.includes("support") ? "active" : "")} to="/merchant/support" onClick={handleNavItemClicked}>
                      Support
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="nav-R">
              <Dropdown>
                <Dropdown.Toggle as="span" className="notifiy-btn" >
                  <img src={NotifyIcon} alt='notification' />
                </Dropdown.Toggle>
                <Dropdown.Menu className="notification-blog">
                  <div className="notification-blog-body">
                    <ul className="notification-list">
                      {notifications.length > 0 ?
                        notifications.map((notification, idx) => (
                          <li className="notification-item" key={idx}>
                            <h4>{notification?.title}</h4>
                            <p>{notification?.content}</p>
                          </li>
                        ))
                        :
                        <li className="text-center">
                          <p>No Notifications</p>
                        </li>}
                    </ul>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle as="span" className="user-profile-drop " >
                  <UserInitials />
                </Dropdown.Toggle>

                <Dropdown.Menu id={"dropdownMenu"} className={` ${showDropdownMenu ? 'show' : ''}`}>
                  <Link onClick={handleRemoveClass} className='dropdown-item' to="/merchant/my-account/profile/detail"><i className="ri-user-3-line"></i> My Account</Link>
                  <Link onClick={handleRemoveClass} className='dropdown-item' to="/merchant/settings"><i className="ri-settings-2-line"></i> Settings</Link>
                  <Dropdown.Item onClick={() => { setShow(true) }}><i className="ri-logout-box-r-line"></i>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </nav>
      </div>
      <Modal className='modal fade form-modal' show={show} backdrop="static" onHide={handleClose}>
        <ModalBody>
          <div className="model-head logout-modal">
            <figure><i class="fas fa-sign-out-alt"></i></figure>
          </div>
          <figcaption>
            <h4>Are you sure you want to logout</h4>
            {/* <p>Thank you for submitting the request. It’s under review and we will get back to you soon.</p> */}
            <div className='logout-modal-button'>
              <button onClick={() => { handleLogout() }} className="btn btn-secondry">Logout</button>
              <button onClick={() => { handleClose() }} className="btn btn-secondry">Cancel</button>
            </div>
          </figcaption>
        </ModalBody>
      </Modal>
    </header>
  )
}
