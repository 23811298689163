import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { chartConfig } from './chartConfig';

const data = [
    {
        name: 'Growth',
        type: 'area',
        fill: 'solid',
        data: [55, 101, 50, 112, 77, 110, 95, 118, 100, 58, 135, 96],
    },
]


export default function GrowthChart() {
    
    return (
        <ReactApexChart
            type="area"
            series={data}
            options={chartConfig}
            height={364}
        />
    )
}
