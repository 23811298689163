import AxiosInterceptor from "../Utils/AxiosInterceptor";

const PREFIX = "access/";

export const getProfile = async () => {
    return await AxiosInterceptor().get(PREFIX + `getProfile`);
};

export const changePassword = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `changePassword`, payload);
};

export const updateProfile = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `updateProfile`, payload);
};
