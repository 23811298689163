import React from 'react'
import { Outlet } from 'react-router-dom'
import logo from '../Assets/images/logo.png'

export default function LogoOnlyLayout() {
  return (
    <div className="wraper-inner">
      <section className="form-page">
        <div className="top-head">
          <div className="container">
              <figure>
                <img src={logo} alt="Insta-coupon Logo" className='login-head-logo-pg'/>
              </figure>
          </div>
        </div>
        <Outlet />                    
      </section>
    </div>
  )
}
