import React, { useEffect, useState } from 'react'
import captchaImage from '../../Assets/images/captcha.png'
import { Link, useNavigate } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup';
import Select from 'react-select';
import { getCategories, signUpService } from '../../Services/AuthServices';
import { tostE } from '../../Utils/Toast';
import { useDispatch } from 'react-redux';
import { setUser } from '../../Actions/ActionCreator';
import { MOBILE_REGEX, NOSPACE_REGEX_EMAIL, PASSWORD_COMPLEX_REGEX } from '../../Utils/Constants';
import logo from '../../Assets/images/logo.png'
import GoogleAutocompleteComp from '../../Components/MyCoupons/GoogleAutocompleteComp';
export default function Signup() {
    const [categoryList, setCategoryList] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false); // State for loader
    const [startTime, setStartTime] = useState(null); // Start time of update process

    useEffect(()=>{
        getCategories().then((res)=>{
            if(res.status===200){
                const resData = res.data.data;
                const modifiedData = resData.map((item)=>({value:item.id, label : item.name}));
                setCategoryList(modifiedData)
            }
        }).catch((err)=>{
            console.log(err)
        })
    },[])

    const signupValidation = Yup.object().shape({
        businessName: Yup.string()
            .required('Business Name is required')
            .max(150, "Business name should be less than 150 characters"),
        representName: Yup.string()
            .required('Representative Name is required')
            .max(150, "Representative name should be less than 150 characters"),
        address: Yup.string()
            .required('Address is required')
            .min(3, 'State must be at least 3 letters long'),
        city: Yup.string()
            .required('City is required')
            .matches(/^[a-zA-Z\s]+$/, 'City must contain only letters')
            .min(3, 'State must be at least 3 letters long'),
        state: Yup.string()
            .required('State is required')
            .matches(/^[a-zA-Z\s]+$/, 'State must contain only letters')
            .min(3, 'State must be at least 3 letters long'),
        zipCode: Yup.string()
            .required('Zip code is required')
            .matches(/^[0-9]+$/, 'Zip code must contain only numbers')
            .min(4, 'Zip code must be at least 4 characters')
            .max(10, 'Zip code must not exceed 10 characters'),
        email: Yup.string()
            .trim()
            .email("Please enter a valid email")
            .required('Email is required')
            .matches(/^(?:\d{10}|[a-zA-Z0-9._%+'-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\s*$)?/, "Please enter a valid Phone number/email"),
        phoneNumber: Yup.string()
            .required('Phone Number is required')
            .matches(MOBILE_REGEX, "Please Enter valid number"),
        terms: Yup.boolean()
            .test('is checked', 'Please accept registration agreement', (value) => value===true),
        humanCheck: Yup.boolean()
            .test('is checked', 'This is required', (value) => value===true),
        categoryIds: Yup.array()
            .min(1, "Select at least one category")
            .required('Please select a category'),
        password: Yup.string()
            .required('Please enter password')
            .min(8, "Password must be 8 characters long alphanumeric containing at least 1 uppercase letter and 1 special character.")
            .matches(PASSWORD_COMPLEX_REGEX, "Password must be 8 characters long alphanumeric containing at least 1 uppercase letter and 1 special character."),
        confirmPassword: Yup.string()
            .required('Please confirm Password')
            .test('passwords-match', 'Passwords must match', function(value){
        return this.parent.password === value
        }),
        termPrivacyTerms: Yup.boolean()
        .test('is checked', 'Please accept Privacy policy and Terms and conditions', (value) => value===true)
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            businessName: "",
            representName: "",
            email: "",
            phoneNumber: "",
            password:"",
            confirmPassword:"",
            address:"",
            city:"",
            state:"",
            zipCode:"",
            categoryIds: [],
            terms: false,
            termPrivacyTerms: false,
            humanCheck: false,
            lat:"",
            lng:""
        },
        validationSchema: signupValidation,
        onSubmit: async (values) => {     
            setStartTime(new Date()); 
            setLoading(true);
            
            values.email = values.email.replace(/\s/g, "");
            const payload = {
                businessName:values.businessName,
                representName: values.representName,
                email: values.email,
                phoneNumber: values.phoneNumber,
                password: values.password,
                confirmPassword: values.confirmPassword,
                address: values.address,
                city:values.city,
                state:values.state,
                zipCode:values.zipCode,
                lat:values.lat,
                lng:values.lng,
                categoryIds: values.categoryIds.map(item=>item.value),
            }
            
            signUpService(payload).then((res)=>{
                if(res.status===200){
                    setTimeout(() => {
                        setLoading(false); // Hide loader after API call completes
                        setStartTime(null); // Reset start time
                        dispatch(setUser(res.data.data))
                       navigate("/auth/phone-verify");
                    }, 500);
                }
            }).catch((err)=>{
                console.log(err);
                setTimeout(() => {
                    setLoading(false); // Hide loader after API call completes
                    setStartTime(null); // Reset start time
                    if(err?.response?.data){
                        tostE(err?.response?.data?.message);
                    }
                },500);

            }).finally(() => {

                setTimeout(() => {
                    setLoading(false); // Hide loader after API call completes
                    setStartTime(null); // Reset start time
                }, 500);
            });
            
        }
    })
    const { errors, values, touched, value, handleChange, handleBlur, handleSubmit, setFieldValue ,isValid } = formik;

    const handleRemoveCategory = (index) =>{
        const selectedCategory = [...values.categoryIds];
        selectedCategory.splice(index, 1);
        setFieldValue("categoryIds", selectedCategory);
    }

    const handlePhoneChange = (e) =>{
        const value = e.target.value;
        if(!isNaN(value)){
            setFieldValue("phoneNumber",value);
        }
    }

    return (
        <>
         {loading && (
                <div className='preload'>
                    <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
                </div>
            )}


            <div className="form-head mb-4 text-center">
                <h4>Create a New Account</h4>
                <p>Create a new account with us</p>
            </div>
            {/* {(!isValid && touched) && (
                <span className="form-error">*All fields are mandatory</span>
            )} */}
            <span className="form-error">*All fields are mandatory</span>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <div className="form-body">
                        <div className="form-group mb-2">
                            <label className="form-label">Business Name</label>
                            <input 
                                name="businessName" 
                                type="text" 
                                placeholder="Enter business name" 
                                className={"form-control" + (errors.businessName && touched.businessName ? " is-invalid" : "")} 
                                value={values.businessName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                title="Business Name"
                            />
                            {errors.businessName && touched.businessName ? <span className="form-error">{errors.businessName}</span> : null}
                        </div>
                        <div className="form-group mb-2">
                            <label className="form-label">Name of the representative</label>
                            <input 
                                name="representName"
                                type="text" 
                                placeholder="Enter representative name" 
                                className={"form-control" + (errors.representName && touched.representName ? " is-invalid" : "")}
                                value={values.representName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                title="Name of the representative"
                            />
                            {errors.representName && touched.representName ? <span className="form-error">{errors.representName}</span> : null}
                        </div>
                        <div className="form-group-double d-flex">
                            <div className="form-group mb-2">
                                <label className="form-label">Email</label>
                                <input 
                                    name="email"
                                    type="email" 
                                    placeholder="Enter email" 
                                    className={"form-control" + (errors.email && touched.email ? " is-invalid" : "")}
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="Email"
                                />
                                {errors.email && touched.email ? <span className="form-error">{errors.email}</span> : null}
                            </div>
                            <div className="form-group mb-2">
                                <label className="form-label">Phone Number</label>
                                <input 
                                    name="phoneNumber"
                                    placeholder="Enter phone number" 
                                    className={"form-control" + (errors.phoneNumber && touched.phoneNumber ? " is-invalid" : "")} 
                                    value={values.phoneNumber}
                                    onChange={(e)=>{handlePhoneChange(e)}}
                                    onBlur={handleBlur}
                                    title="Phone Number"
                                />
                                {errors.phoneNumber && touched.phoneNumber ? <span className="form-error">{errors.phoneNumber}</span> : null}
                            </div>
                        </div>
                        <div className="form-group-double d-flex">
                            <div className="form-group mb-2">
                                <label className="form-label">Password</label>
                                <div className='password-field'>
                                    <input 
                                        name="password"
                                        type="password" 
                                        placeholder="Enter password" 
                                        className={"form-control" + (errors.password && touched.password ? " is-invalid" : "")} 
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        title="New Password"
                                        autoComplete='off'
                                    />      
                                    {errors.password && touched.password ? <span className="form-error form-error-special">{errors.password}</span> : null}                          
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label className="form-label">Confirm Password</label>
                                <input 
                                    name="confirmPassword"
                                    type="password" 
                                    placeholder="Confirm password" 
                                    className={"form-control" + (errors.confirmPassword && touched.confirmPassword ? " is-invalid" : "")} 
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="New Password"
                                    autoComplete='off'
                                />
                                {errors.confirmPassword && touched.confirmPassword ? <span className="form-error">{errors.confirmPassword}</span> : null}                          
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label className="form-label">Address</label>
                            <GoogleAutocompleteComp formik={formik} />
                            {/* <input 
                                name="address"
                                type="text" 
                                placeholder="Enter address" 
                                className={"form-control" + (errors.address && touched.address ? " is-invalid" : "")}
                                value={values.address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                title="Address"
                            />
                            {errors.address && touched.address ? <span className="form-error">{errors.address}</span> : null} */}
                        </div>

                        {/******  Add City ,State , and Zip code  ****/}

                        <div className="form-group mb-2">
                            <label className="form-label">City</label>
                            <input 
                                name="city"
                                type="text" 
                                placeholder="Enter City" 
                                className={"form-control"+ (errors.city && touched.city ? " is-invalid" : "")}
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                title="City"
                            />
                            {errors.city && touched.city ? <span className="form-error">{errors.city}</span> : null}
                        </div>


                        <div className="form-group mb-2">
                            <label className="form-label">State</label>
                            <input 
                                name="state"
                                type="text" 
                                placeholder="Enter State" 
                                className={"form-control"+ (errors.state && touched.state ? " is-invalid" : "")}
                                value={values.state}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                title="State"
                            />
                            {errors.state && touched.state ? <span className="form-error">{errors.state}</span> : null}
                        </div>

                        <div className="form-group mb-2">
                            <label className="form-label">Zip Code</label>
                            <input 
                                name="zipCode"
                                type="text" 
                                placeholder="Enter State" 
                                className={"form-control"+ (errors.zipCode && touched.zipCode ? " is-invalid" : "")}
                                value={values.zipCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                title="Zip Code"
                            />
                            {errors.zipCode && touched.zipCode ? <span className="form-error">{errors.zipCode}</span> : null}
                        </div>
                            {/******Add City State and Zip code****/}


                        <div className="form-group mb-4">
                            <label className="form-label">Category</label>
                            <Select
                                className={"custom-select" + (errors.categoryIds && touched.categoryIds ? " is-invalid" : "")}
                                name="category"
                                id="category"
                                title="Category"
                                placeholder={"Select Category"}
                                options={categoryList}
                                onBlur={handleBlur}
                                isMulti={true}
                                onChange={(value) => {
                                    setFieldValue("categoryIds", value);
                                }}
                                value={values.categoryIds}
                            />                            
                            {errors.categoryIds && touched.categoryIds ? <span className="form-error">{errors.categoryIds}</span> : null}                            
                            <ul className="select-list-option mt-3">                                
                                {values.categoryIds.map((item, idx)=>{                                
                                return(
                                    <li key={idx}>
                                        {item?.label}
                                        <span onClick={()=>{handleRemoveCategory(idx)}} className="option-close"><i className="ri-close-fill"></i></span>
                                    </li>
                                )})}                                
                            </ul>
                            <div className="form-check mt-3">
                                <input name="terms" className="form-check-input" type="checkbox" id="flexCheckChecked" checked={values.terms} onChange={handleChange} />
                                <label className="form-check-label" for="flexCheckChecked">
                                    I Agree to <Link to="/common/reg-agreement" target='_blank' className="form-link"> Registration Agreement </Link>
                                </label>
                            </div>
                                                        
                            {errors.terms && touched.terms ? <span className="form-error">{errors.terms}</span> : null}

                            <div className="form-check mt-3">
                                <input name="termPrivacyTerms" className="form-check-input" type="checkbox" id="flexCheckChecked22" checked={values.termPrivacyTerms} onChange={handleChange} />
                                <label className="form-check-label" htmlFor="flexCheckChecked22">
                                    I Agree to <Link to="/common/privacy-policy" target='_blank' className="form-link"> Privacy Policy </Link> 
                                    and <Link to="/common/termsandconditions" target='_blank' className="form-link"> Terms and Conditions </Link>
                                </label>
                            </div>

                            {errors.termPrivacyTerms && touched.termPrivacyTerms ? <span className="form-error">{errors.termPrivacyTerms}</span> : null}

                            <hr className="form-divider" />
                            <div className="capcha-box d-flex justify-content-between align-items-center">
                                <div className="capcha-box-left">
                                    <div className="form-check ">
                                        <input name="humanCheck" className="form-check-input" type="checkbox" id="flexCheckChecked11" checked={values.humanCheck} onChange={handleChange} />
                                        <label className="form-check-label" for="flexCheckChecked11">
                                            I’m not a robot
                                        </label>
                                    </div>
                                    {errors.humanCheck && touched.humanCheck ? <span className="form-error">{errors.humanCheck}</span> : null}
                                </div>
                                <div className="capcha-box-right"><img src={captchaImage} alt="captcha" /></div>
                            </div>
                        </div>
                        <div className="form-btn-box mt-4">
                            <button type="submit" className="btn btn-secondry w-100">Join US </button>
                        </div>
                    </div>
                </Form>
            </FormikProvider>
            <div className="form-bottom form-bottom-text">
                <span>
                    Already have account?{" "}
                    <Link
                        to={"/auth/login"}
                        className="form-link"
                    >
                        Log In
                    </Link>
                </span>
            </div>
        </>
    )
}
