import { Avatar } from "@mui/material";
import Select from "react-select";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { tostE, tostS } from "../../../Utils/Toast";
import axios from "axios";
import logo from '../../../Assets/images/logo.png'
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import {
  getBusinessDetails,
  updateBusinessDetails,
} from "../../../Services/Businessconfig";
import * as Yup from "yup";
// import TimePicker from "react-time-picker";
import { getCategories } from "../../../Services/AuthServices";
import { MEDIA_BASE_URL } from "../../../Utils/Constants";
import GoogleAutocompleteComp from "../../../Components/MyCoupons/GoogleAutocompleteComp";

const validationSchema = Yup.object().shape({
  businessImage: Yup.mixed().required("Business image is required"),
  address: Yup.string()
    .required("Address is required")
    .max(150, "Address should be less than 150 characters")
    .matches(
      /^[^\s]+(?:\s.*)?$/,
      "Address should not start with a blank space"
    ),
  city: Yup.string()
    .required("City is required")
    .max(150, "City should be less than 150 characters")
    .matches(/^[^\s]+(?:\s.*)?$/, "City should not start with a blank space"),
  state: Yup.string()
    .required("State is required")
    .max(150, "State should be less than 150 characters")
    .matches(/^[^\s]+(?:\s.*)?$/, "State should not start with a blank space"),
  zipCode: Yup.string()
    .required('Zip code is required')
    .matches(/^[0-9]+$/, 'Zip code must contain only numbers')
    .min(4, 'Zip code must be at least 4 characters')
    .max(10, 'Zip code must not exceed 10 characters'),
  businessHourStartTime: Yup.string().required("Start time is required"),
  businessHourEndTime: Yup.string()
    .required("End time is required")
    .test('is-before-max-time', 'End time must be before 11:30 PM', function(value) {
      if (!value) return true;
      const selectedTime = dayjs(value, 'HH:mm');
      const maxTime = dayjs().hour(23).minute(31);
      return selectedTime.isBefore(maxTime);
    })
    .when(["businessHourStartTime"], (startTime, schema) => {
      return schema.test({
        test: function (endTime) {
          if (!endTime || !startTime) return true;

          const [startHour, startMinute] = String(startTime)
            .split(":")
            .map(Number);
          const [endHour, endMinute] = String(endTime).split(":").map(Number);
          console.log(endHour)
          if (endHour > startHour) return true;
          if (endHour === startHour && endMinute > startMinute) return true;

          return false;
        },
        message: "Ensure that the start time is before the end time.",
      });
    }),
  categoryIds: Yup.array().min(1, "Service category is required"),
  businessContactNumber: Yup.string()
    .required("Business contact number is required")
    .matches(/^\d{10}$/, "Invalid phone number format")
    .max(150, "Business contact number should be less than 150 characters")
    .matches(
      /[^\s*].*[^\s*]/g,
      "Business contact number should not start with a blank space"
    ),
  leadTime: Yup.number()
    .required("Lead time is required")
    .positive("Lead time must be a positive number"),
  policy: Yup.string()
    .required("Policy is required")
    .matches(/[^\s*].*[^\s*]/g, "Policy should not start with a blank space")
    .min(10, "Policy should be of at least  10 characters"),
  bankAccountNumber: Yup.string()
    .required("Bank account number is required")
    .max(150, "Bank account number should be less than 150 characters")
    .matches(
      /^[^\s]+(?:\s.*)?$/,
      "Bank account number should not start with a blank space"
    ),
  bankRoutingNumber: Yup.string()
    .required("Bank routing number is required")
    .max(150, "Bank routing number should be less than 150 characters")
    .matches(
      /^[^\s]+(?:\s.*)?$/,
      "Bank routing number should not start with a blank space"
    ),
});

export default function UpdateBusinessConfig() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [startTime, setStartTime] = useState(null); 
  const [loading, setLoading] = useState(false);
  const [routingnumber, setRoutingnumber] = useState('');
  useEffect(() => {

    getCategories()
      .then((res) => {
        if (res.status === 200) {
          const resData = res.data.data;
          const modifiedData = resData.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setCategoryList(modifiedData);
        }
      })
      .catch((err) => {
      });
  }, []);

  useEffect(() => {
    setStartTime(new Date()); 
    setLoading(true);
    async function fetchData() {
      try {
        const response = await getBusinessDetails();
        setData(response.data.data);
        const routingNumber = response?.data?.data?.bankRoutingNumber;
        const maskedRoutingNumber = routingNumber && routingNumber.length > 4 ? 'XXXXXXXX-' + routingNumber.substr(-4) : routingNumber;
        setRoutingnumber(maskedRoutingNumber);
      } catch (error) {
        console.error("Error fetching business details:", error);
      } finally {
        setTimeout(() => {
          setLoading(false);
          setStartTime(null);
        }, 1000);
      }
    }
    fetchData();
  }, []);
  function getFileExtension(fileName) {
    return fileName.slice(
      (Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1
    );
  }
  const formik = useFormik({
    initialValues: {
      imagePath: "merchant",
      businessImage: data?.businessImage || "",
      address: data?.address || "",
      city: data?.city || "",
      state: data?.state || "",
      zipCode: data?.zipCode || "",
      businessHourStartTime: data?.businessHourStartTime || '08:00:00',
      businessHourEndTime: data?.businessHourEndTime || '20:00:00',
      categoryIds: data?.categoryIds?.map((item) => ({ value: item?.id, label: item?.name })) || [],
      businessContactNumber: data?.businessContactNumber || "",
      leadTime: data?.leadTime || "5",
      policy: data?.policy || "",
      notificationText: data?.notificationText || false,
      notificationEmail: data?.notificationEmail || false,
      bankAccountNumber: data?.bankAccountNumber ? `XXXXXXXX-${data?.bankAccountNumber}` : "",
      bankRoutingNumber: data?.bankRoutingNumber ? routingnumber : '',
      lat: data?.lat || "",
      lng: data?.lng || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        let formData = new FormData();
        Object.keys(values).forEach((key) => {
          if ((key === 'bankAccountNumber' || key === 'bankRoutingNumber') && values[key].includes('XXXX')) {
            return;
          }
          if (key == "categoryIds") {
            formData.append(
              key,
              JSON.stringify(values[key].map((item) => item?.value))
            );
            return
          }
          if (values[key] instanceof File) {
            let ext = getFileExtension(values[key].name);
            formData.append(key, values[key], `${key}.${ext}`);
            return;
          }
          formData.append(key, values[key]);
        });

        const response = await updateBusinessDetails(formData);
        tostS(response?.data?.message);
        navigate("/merchant/business-config/detail");
      } catch (error) {
        tostE(error?.response?.data?.message)
      } 
    },
  });
  const {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setValues,
    setFieldError,
  } = formik;

  const handleImageChange = async (e, setFieldValue) => {
    if (
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/jpeg"
    ) {
      if (parseInt(e.target.files[0].size) <= 5145728) {
        const reader = new FileReader();

        reader.onload = function (event) {
          const img = new Image();
          img.src = event.target.result;

          img.onload = function () {
            setFieldValue("businessImage", e.target.files[0]);
          };

          img.onerror = function () {
            tostE("The uploaded image is corrupted.");
          };
        };

        // Read the uploaded file as a data URL
        reader.readAsDataURL(e.target.files[0]);
      } else {
        tostE("Please upload a file smaller than 5 MB");
      }
    } else {
      tostE("Please upload a file with .png, .jpg, or .jpeg format.");
    }
  };

  console.log(formik.values)

  return (
    <div class="add-new-coupon-sec section-space">
      {loading && (
        <div className='preload'>
          <img src={logo} alt="Insta-coupon Logo" className='preloadlogo' />
        </div>
      )}
      <div class="container">
        <div class="coupan-sec-top-head mb-4">
          <div class="page-title ">
            <h1>Edit Business Configuration</h1>
          </div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/merchant/business-config/detail">
                  Business Configuration
                </Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Edit Business Configuration
              </li>
            </ol>
          </nav>
        </div>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <div class="add-new-coupon-info-main mt-5">
              <div class="row">
                <div class="col-md-8 add-new-coupon-box m-auto">
                  <div class="box-shadow ">
                    <div class="upload-photo m-auto">
                      <div class="avatar-upload">
                        <div class="avatar-edit">
                          <input
                            type="file"
                            id="imageUpload"
                            accept=".png, .jpg, .jpeg"
                            onChange={(e) => { handleImageChange(e, setFieldValue) }}
                          />
                          <label for="imageUpload" class="img-upload-icon">
                            <i class="ri-add-fill"></i>
                          </label>
                        </div>
                        {values?.businessImage instanceof File ? (
                          <Avatar
                            className="avatar-preview"
                            src={URL.createObjectURL(values?.businessImage)}
                          />
                        ) : (<Avatar
                          className="avatar-preview"
                          src={values?.businessImage ? MEDIA_BASE_URL + values?.businessImage : ""}
                        />
                        )}
                      </div>
                      <div className="text-center">

                        {errors.businessImage && touched.businessImage ? (
                          <span className="form-error">
                            {errors.businessImage}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div class="row">
                      <h4 class="sub-title-page mb-3 mt-3">Business Details</h4>
                      <div class="col-md-6 mb-3">
                        <label className="form-label">Address</label>
                        <GoogleAutocompleteComp formik={formik} />
                        {/* <input
                          type="text"
                          name="address"
                          placeholder="Enter address"
                          className={
                            "form-control" +
                            (errors.address && touched.address
                              ? " is-invalid"
                              : "")
                          }
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          title="Address"
                        /> */}
                        {errors.address && touched.address ? (
                          <span className="form-error">{errors.address}</span>
                        ) : null}
                      </div>
                      <div class="col-md-6 mb-3">
                        <label className="form-label">City</label>
                        <input
                          type="text"
                          name="city"
                          placeholder="Enter city"
                          className={
                            "form-control" +
                            (errors.city && touched.city ? " is-invalid" : "")
                          }
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          title="City"
                        />
                        {errors.city && touched.city ? (
                          <span className="form-error">{errors.city}</span>
                        ) : null}
                      </div>
                      <div class="col-md-6 mb-3">
                        <label className="form-label">State</label>
                        <input
                          type="text"
                          name="state"
                          placeholder="Enter state"
                          className={
                            "form-control" +
                            (errors.state && touched.state ? " is-invalid" : "")
                          }
                          value={values.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          title="State"
                        />
                        {errors.state && touched.state ? (
                          <span className="form-error">{errors.state}</span>
                        ) : null}
                      </div>
                      <div class="col-md-6 mb-3">
                        <label class="form-label">Zip Code</label>
                        <input
                          type="text"
                          name="zipCode"
                          placeholder="Enter zip code"
                          className={
                            "form-control" +
                            (errors.zipCode && touched.zipCode
                              ? " is-invalid"
                              : "")
                          }
                          value={values.zipCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          title="Zip Code"
                        />
                        {errors.zipCode && touched.zipCode ? (
                          <span className="form-error">{errors.zipCode}</span>
                        ) : null}
                      </div>
                      <div class="col-md-6 mb-3">
                        <label class="form-label">Business Hours</label>
                        <div class="row">
                          <div class="col-md-6 mb-3">

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['TimePicker']} >
                                <TimePicker
                                  value={dayjs(formik.values.businessHourStartTime, "HH:mm")}
                                  className='mobilepicker-custom timepicker-custom'
                                  onChange={(e) => {
                                    console.log(new Date(e));

                                    setFieldValue("businessHourStartTime", (e));
                                    const formattedStartTime = dayjs(e, "HH:mm").format("HH:mm:ss");
                                    console.log(formattedStartTime)
                                    setFieldValue("businessHourStartTime", formattedStartTime); // Update the formik value

                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            {errors.businessHourStartTime &&
                              touched.businessHourStartTime ? (
                              <span className="form-error">
                                {errors.businessHourStartTime}
                              </span>
                            ) : null}
                          </div>
                          <div class="col-md-6">

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['TimePicker']}>
                                <TimePicker
                                  value={dayjs(formik.values.businessHourEndTime, "HH:mm")}
                                  className='mobilepicker-custom timepicker-custom'
                                  onChange={(e) => {
                                    const selectedTime = dayjs(e, "HH:mm");
                                    const maxTime = dayjs().hour(23).minute(30); // Setting max time to 11:29 PM
                                    if (selectedTime.isAfter(maxTime)) {
                                      console.log("Selected time exceeds maximum allowed time.");
                                      return; // Prevent setting time beyond max time
                                    }
                                    const formattedEndTime = selectedTime.format("HH:mm:ss");
                                    console.log(formattedEndTime)
                                    setFieldValue("businessHourEndTime", formattedEndTime); // Update the formik value
                                  }}
                                  maxTime={dayjs().hour(23).minute(30)} // Set max time for TimePicker
                                />
                              </DemoContainer>
                            </LocalizationProvider>

                            {errors?.businessHourEndTime &&
                              touched?.businessHourEndTime ? (
                              <span className="form-error">
                                {errors?.businessHourEndTime}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 mb-3">
                        <label class="form-label">Contact Number</label>
                        <input
                          type="text"
                          name="businessContactNumber"
                          placeholder="Enter contact number"
                          className={
                            "form-control" +
                            (errors.businessContactNumber &&
                              touched.businessContactNumber
                              ? " is-invalid"
                              : "")
                          }
                          value={values.businessContactNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          title="Contact Number"
                        />
                        {errors.businessContactNumber &&
                          touched.businessContactNumber ? (
                          <span className="form-error">
                            {errors.businessContactNumber}
                          </span>
                        ) : null}
                      </div>

                      <div class="col-md-6 mb-3">
                        <label class="form-label">Lead Time</label>
                        <div class="row">
                          <div class="col-md-12 mb-6">
                            <select
                              class="form-select"
                              onChange={(e) => {
                                console.log(e.target.value);
                                setFieldValue("leadTime", e.target.value);
                              }}
                              value={values.leadTime}
                            >
                              <option value="5">5 Minutes</option>
                              <option value="10">10 Minutes</option>
                              <option value="15">15 Minutes</option>
                              <option value="20">20 Minutes</option>
                              <option value="25">25 Minutes</option>
                              <option value="30">30 Minutes</option>
                              <option value="35">35 Minutes</option>
                              <option value="40">40 Minutes</option>
                              <option value="45">45 Minutes</option>
                              <option value="50">50 Minutes</option>
                              <option value="55">55 Minutes</option>
                              <option value="60">60 Minutes</option>
                            </select>
                            {errors.leadTime && touched.leadTime ? (
                              <span className="form-error">
                                {errors.leadTime}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 mb-3">
                        <label class="form-label">Notifications</label>
                        <div class="switch-box d-flex">
                          <div class="switch-box-content d-flex me-3">
                            <label class="sub-label me-2">Text</label>
                            <div class="switch-toggle-sm">
                              <label class="switch">
                                <input
                                  type="checkbox"
                                  name="notificationText"
                                  checked={values.notificationText}
                                  onChange={handleChange}
                                />
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <div class="switch-box-content d-flex">
                            <label class="sub-label me-2">Email</label>
                            <div class="switch-toggle-sm">
                              <label class="switch">
                                <input
                                  type="checkbox"
                                  name="notificationEmail"
                                  checked={values.notificationEmail}
                                  onChange={handleChange}
                                />
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mb-3">
                        <label class="form-label">Policy</label>
                        <textarea
                          type="text"
                          name="policy"
                          placeholder="Enter Policy"
                          className={
                            "form-control textarea-box" +
                            (errors.policy && touched.policy
                              ? " is-invalid"
                              : "")
                          }
                          value={values.policy?.replace(/ +/g, " ")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          title="Policy"
                        />
                        {errors.policy && touched.policy ? (
                          <span className="form-error">{errors.policy}</span>
                        ) : null}
                      </div>
                      <h4 class="sub-title-page mb-3 mt-3">Category</h4>
                      <div class="col-md-12 mb-6">
                        <label class="form-label">Service Category</label>
                        <Select
                          value={values?.categoryIds}
                          isMulti
                          onChange={(value) => {
                            console.log(value)
                            setFieldValue("categoryIds", [...value]);
                          }}
                          options={categoryList}
                        />
                        {errors.categoryIds &&
                          touched.categoryIds ? (
                          <span className="form-error">
                            {errors.categoryIds}
                          </span>
                        ) : null}
                      </div>
                      <h4 class="sub-title-page mb-3 mt-3">
                        Bank Account Details
                      </h4>
                      <div class="col-md-6 mb-3">
                        <label class="form-label">Account Number</label>
                        <input
                          type="text"
                          name="bankAccountNumber"
                          placeholder="Enter account number"
                          className={
                            "form-control" +
                            (errors.bankAccountNumber &&
                              touched.bankAccountNumber
                              ? " is-invalid"
                              : "")
                          }
                          value={values.bankAccountNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          title="Account Number"
                        />
                        {errors.bankAccountNumber &&
                          touched.bankAccountNumber ? (
                          <span className="form-error">
                            {errors.bankAccountNumber}
                          </span>
                        ) : null}
                      </div>
                      <div class="col-md-6 mb-3">
                        <label class="form-label">Routing Number</label>
                        <input
                          type="text"
                          name="bankRoutingNumber"
                          placeholder="Enter routing number"
                          className={
                            "form-control" +
                            (errors.bankRoutingNumber &&
                              touched.bankRoutingNumber
                              ? " is-invalid"
                              : "")
                          }
                          value={values.bankRoutingNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          title="Routing Number"
                        />
                        {errors.bankRoutingNumber &&
                          touched.bankRoutingNumber ? (
                          <span className="form-error">
                            {errors.bankRoutingNumber}
                          </span>
                        ) : null}
                      </div>
                      <div class="col-md-12 text-center mt-4 btn-group-box">
                        <button type="submit" className="btn btn-secondry max-w">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
}
