import React from 'react'
import { useSelector } from 'react-redux'
import { InitialRender } from '../../Utils/CommonFunctions';

export default function UserInitials() {

    const user = useSelector(state => state.user.data && state.user.data ? state.user.data : null);
    return (
        <>
            <span>{user?.businessName ?  InitialRender([user?.businessName]) : "NA"}</span>
        </>
    )
}
