import AxiosInterceptor from "../Utils/AxiosInterceptor";

const PREFIX = "access/";

export const sendInquiry = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `sendInquiry`, payload);
};


export const sendInquiryBeforeLogin = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `sendInquiryBeforeLogin`, payload);
};