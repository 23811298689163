import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CouponCard from '../../../Components/MyCoupons/CouponCard';
import logo from '../../../Assets/images/logo.png'
import PublishedCouponList from './PublishedCouponList'
import CouponTemplateList from './CouponTemplateList'

export default function CouponList() {
    const {tab,page} = useParams();
    const [loading, setLoading] = useState(false); // State for loader

    const navigate = useNavigate();

    
    return (
        <div className="coupan-sec section-space">
            <div className="container">
                {/* Your existing JSX */}
                <div className="coupan-sec-top-head d-flex align-items-center justify-content-between mb-4">
                    <div className="page-title ">
                        <h1>My Coupons</h1>
                    </div>
                    <div className="coupan-sec-top-head-right d-flex align-items-center">
                        <ul className="coupan-sec-list d-flex ms-3">
                            <li className="active">
                                <Link to="/merchant/my-coupon/published-coupon/list">
                                    <i className="ri-list-check"></i>
                                </Link>
                            </li>
                            <li>
                                <Link to="/merchant/my-coupon/published-coupon/calender/list">
                                    <i className="ri-calendar-line"></i>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="content coupon-tag-box">
                    <ul className="coupon-tag">
                        <li className={tab === 'published-coupon' ? 'active' : ''} onClick={() => navigate('/merchant/my-coupon/published-coupon/list')}>
                            Published Coupons
                        </li>
                        <li className={tab === 'coupon-template' ? 'active' : ''} onClick={() => navigate('/merchant/my-coupon/coupon-template/list')}>
                            Coupon Templates
                        </li>
                    </ul>
                </div>
                
                {loading && (
                    <div className='preload'>
                        <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
                    </div>
                )}

                {!loading && (
                    <>
                        {tab === 'published-coupon' && (
                            <PublishedCouponList />
                        )}

                        {tab === 'coupon-template' && (
                            <CouponTemplateList />
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
