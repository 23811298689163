import { ToastContainer } from 'react-toastify';
import Routes from './Routes';
import store from './Store/store';
import { Provider } from 'react-redux';

function App() {
  store.subscribe(() => {
    localStorage.setItem('reduxStore', JSON.stringify(store.getState()));
   
  })

  return (    
    <Provider store={store}>
      <ToastContainer />
      <Routes />
    </Provider>    
  );
}

export default App;
