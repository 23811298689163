import React, { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../Services/AuthServices';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { setUser } from '../../Actions/ActionCreator';
import { tostE } from '../../Utils/Toast';
import { NOSPACE_REGEX_EMAIL } from '../../Utils/Constants';
import logo from './../../Assets/images/logo.png'

export default function ForgotPassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false); // State for loader
    const [startTime, setStartTime] = useState(null); // Start time of update process


    const forgotPassSchema = Yup.object().shape({
        email: Yup.string()
            .trim() // Trim leading and trailing white spaces
            .email("Please enter a valid email")
            .required('Email is required')
            .matches(/^(?!\s).*[a-zA-Z0-9._%+'-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}.*$/, "Please enter a valid email"), // Adjusted regex
    })
    

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: ""
        },
        validationSchema: forgotPassSchema,
        onSubmit: async (values) => {
            setStartTime(new Date()); // Record start time
            setLoading(true); // Show loader before making API call            
            const payload = {
                email: values.email
            }
            forgotPassword(payload).then((res) => {
                
                if (res.status === 200) {
                    setTimeout(() => {
                        setLoading(false); // Hide loader after API call completes
                        setStartTime(null); // Reset start time
                       dispatch(setUser(res.data.data))
                       navigate("/auth/verify", { replace: true })
                    }, 2000);                    

                }
            }).catch((err) => {
                console.log(err);
                if (err?.response?.data) {
                    tostE(err?.response?.data?.message);
                }
            }).finally((err1) => {
                setTimeout(() => {
                    setLoading(false); // Hide loader after API call completes
                    setStartTime(null); // Reset start time
                    tostE(err1?.response?.data?.message);
                }, 500);
            });   
        }
    });

    const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik;
    const getDuration = () => {
        if (startTime) {
            const endTime = new Date();
            const duration = ((endTime - startTime) / 1000); // Calculate duration in seconds
            return duration.toFixed(2) + ' seconds';
        }
        return '';
    };

    return (
        <>
            {loading && (
                <div className='preload'>
                    <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
                </div>
            )}
            <div className="form-head mb-4 text-center">
                <h4>Forgot Password</h4>
                <p>Enter your registered email</p>
            </div>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <div className="form-body">
                        <div className="form-group mb-4">
                            <label className="form-label">Email</label>
                            <input
                                name="email"
                                type="email"
                                placeholder="Enter email"
                                className={"form-control" + (errors.email && touched.email ? " is-invalid" : "")}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                title="Email"
                            />
                            {errors.email && touched.email && <span className="form-error">{errors.email}</span>}
                        </div>
                        <div className="form-btn-box mt-4">
                            <button type='submit' className="btn btn-secondry w-100">Continue</button>
                        </div>
                    </div>
                </Form>
            </FormikProvider>
            <div className="form-bottom ">
                <span>
                    <Link
                        to={"/auth/login"}
                        className="form-link text-center d-flex justify-content-center mt-3"
                    >
                        Back to Login
                    </Link>
                </span>
            </div>
        </>
    )
}
