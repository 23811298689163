import React, { useEffect, useState } from 'react';
import { getProfile, updateProfile } from '../../Services/MyAccountServices';
import { getCategories } from '../../Services/AuthServices';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { tostE, tostS } from '../../Utils/Toast';
import { LINK_REGEX, NOSPACE_REGEX_EMAIL, URL_REGEX } from '../../Utils/Constants';
import { useNavigate } from 'react-router-dom';
import logo from '../../Assets/images/logo.png'

export default function MyProfileUpdate() {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [categoryList, setCategoryList] = useState([]);
    const [loading, setLoading] = useState(false); // State for loader
    const [startTime, setStartTime] = useState(null); // Start time of update process

   useEffect(() => {
    setStartTime(new Date()); // Record start time
    setLoading(true); // Show loader before making API call
        getProfile().then((res) => {
            if (res.status === 200) {
                setTimeout(() => {
                    setLoading(false); // Hide loader after API call completes
                    setStartTime(null); // Reset start time
                const respData = res.data.data;
                const categories = respData.categoryIds.map(item => ({ value: item.id, label: item.name }));
                setUser({
                    ...respData,
                    categories
                });
            },500);
            }
        }).catch((err) => { console.log(err) });
        getCategories().then((res) => {
            if (res.status === 200) {
                const resData = res.data.data;
                const modifiedData = resData.map((item) => ({ value: item.id, label: item.name }));
                setCategoryList(modifiedData);
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const profileValidation = Yup.object().shape({
        businessName: Yup.string().required('Business Name is required'),
        representName: Yup.string().required('Representative Name is required'),
        email: Yup.string().email("Please enter a valid email").required('Email is required').matches(NOSPACE_REGEX_EMAIL, "Please Enter a valid email"),
        phoneNumber: Yup.string().required('Phone Number is required').min(3, "Please enter a valid phone number").max(10, "Please enter a valid phone number"),
        categoryIds: Yup.array().min(1, "Select at least one category").required('Please select a category'),
        fbLink:Yup.string().matches(LINK_REGEX, "Please enter a valid URL"),
        instaLink:Yup.string().matches(LINK_REGEX, "Please enter a valid URL"),        
        twitterLink:Yup.string().matches(LINK_REGEX, "Please enter a valid URL"),        
        website:Yup.string().matches(LINK_REGEX, "Please enter a valid URL"),        
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            businessName: user?.businessName ? user?.businessName : "",
            representName: user?.representName ? user?.representName : "",
            email: user?.email ? user?.email : "",
            phoneNumber: user?.phoneNumber ? user?.phoneNumber : "",
            aboutUs: user?.aboutUs ? user?.aboutUs : "",
            fbLink: user?.fbLink ? user?.fbLink : "",
            instaLink: user?.instaLink ? user?.instaLink : "",
            twitterLink: user?.twitterLink ? user?.twitterLink : "",            
            website: user?.website ? user?.website : "",            
            categoryIds: user?.categories ? user?.categories : []
        },
        validationSchema: profileValidation,
        onSubmit: async (values) => {
            setStartTime(new Date()); // Record start time
            setLoading(true); // Show loader before making API call
            const payload = {
                businessName: values.businessName,
                representName: values.representName,
                email: values.email,
                phoneNumber: values.phoneNumber,
                aboutUs: values.aboutUs,
                fbLink: values.fbLink,
                instaLink: values.instaLink,
                twitterLink: values.twitterLink,                
                website: values.website,                
                categoryIds: values.categoryIds.map(item=>item.value)
            }

            updateProfile(payload).then((res) => {
                if (res.status === 200) {
                    setTimeout(() => {
                        setLoading(false); // Hide loader after API call completes
                        setStartTime(null); // Reset start time
                        tostS(res.data.message);
                        navigate("/merchant/my-account/profile/detail");
                    },500);
                  
                }
            }).catch((err) => {
                console.log(err);
                if (err?.response?.data) {
                    tostE(err?.response?.data?.message);
                }
            }).finally(() => {

                setTimeout(() => {
                    setLoading(false); // Hide loader after API call completes
                    setStartTime(null); // Reset start time
                }, 2000);
            });
        }
    });


    const { errors, values, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

    const handleRemoveCategory = (index) => {
        const selectedCategory = [...values.categoryIds];
        selectedCategory.splice(index, 1);
        setFieldValue("categoryIds", selectedCategory);
    }    
    const getDuration = () => {
        if (startTime) {
            const endTime = new Date();
            const duration = ((endTime + startTime) / 1000)+2000; // Calculate duration in seconds
            return duration.toFixed(2) + ' seconds';
        }
        return '';
    };
    return (
        <div className="tab-pane fade show active">
            {loading && (
                <div className='preload'>
                    <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
                </div>
            )}
            <div className="box-shadow">
                <div className="my-account-right-head mb-4">
                    <div className="section-title ">
                        <h2>Edit Profile</h2>
                    </div>
                </div>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Business Name *</label>
                                <input
                                    name="businessName"
                                    type="text"
                                    placeholder="Enter business name"
                                    className={"form-control" + (errors.businessName && touched.businessName ? " is-invalid" : "")}
                                    value={values.businessName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="Business Name"
                                />
                                {errors.businessName && touched.businessName ? <span className="form-error">{errors.businessName}</span> : null}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Representative Name *</label>
                                <input
                                    name="representName"
                                    type="text"
                                    placeholder="Enter representative name"
                                    className={"form-control" + (errors.representName && touched.representName ? " is-invalid" : "")}
                                    value={values.representName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="Representative Name"
                                />
                                {errors.representName && touched.representName ? <span className="form-error">{errors.representName}</span> : null}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Email *</label>
                                <input
                                    name="email"
                                    disabled
                                    type="email"
                                    placeholder="Enter email"
                                    className={"form-control" + (errors.email && touched.email ? " is-invalid" : "")}
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="Email"
                                />
                                {errors.email && touched.email ? <span className="form-error">{errors.email}</span> : null}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Phone Number *</label>
                                <input
                                    name="phoneNumber"
                                    disabled
                                    type="number"
                                    placeholder="Enter phone number"
                                    className={"form-control" + (errors.phoneNumber && touched.phoneNumber ? " is-invalid" : "")}
                                    value={values.phoneNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="Phone Number"
                                />
                                {errors.phoneNumber && touched.phoneNumber ? <span className="form-error">{errors.phoneNumber}</span> : null}
                            </div>
                            <div className="col-md-12 mb-3">
                                <label className="form-label">Category *</label>
                                <Select
                                    className={"custom-select" + (errors.categoryIds && touched.categoryIds ? " is-invalid" : "")}
                                    name="category"
                                    id="category"
                                    title="Category"
                                    placeholder={"Select Category"}
                                    options={categoryList}
                                    onBlur={handleBlur}
                                    isMulti={true}
                                    onChange={(value) => {
                                        setFieldValue("categoryIds", value);
                                    }}
                                    value={values.categoryIds}
                                />
                                {errors.categoryIds && touched.categoryIds ? <span className="form-error">{errors.categoryIds}</span> : null}
                                <ul className="select-list-option mt-3">
                                    {values.categoryIds.map((item, idx) => {
                                        return (
                                            <li key={idx}>
                                                {item?.label}
                                                <span onClick={() => { handleRemoveCategory(idx) }} className="option-close"><i className="ri-close-fill"></i></span>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>                            
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Facebook Link </label>
                                <input
                                    type="text"
                                    name="fbLink"
                                    placeholder="Enter Facebook lnk"
                                    className={"form-control" + (errors.fbLink && touched.fbLink ? " is-invalid" : "")}
                                    value={values.fbLink}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="Facebook Link"
                                />
                                {errors.fbLink && touched.fbLink ? <span className="form-error">{errors.fbLink}</span> : null}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Instagram Link </label>
                                <input
                                    type="text"
                                    name="instaLink"
                                    placeholder="Enter Instagram link"
                                    className={"form-control" + (errors.instaLink && touched.instaLink ? " is-invalid" : "")}
                                    value={values.instaLink}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="Instagram Link"
                                />
                                {errors.instaLink && touched.instaLink ? <span className="form-error">{errors.instaLink}</span> : null}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Twitter Link </label>
                                <input
                                    type="text"
                                    name="twitterLink"
                                    placeholder="Enter Twitter link"
                                    className={"form-control" + (errors.twitterLink && touched.twitterLink ? " is-invalid" : "")}
                                    value={values.twitterLink}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="Twitter Link"
                                />
                                {errors.twitterLink && touched.twitterLink ? <span className="form-error">{errors.twitterLink}</span> : null}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Website </label>
                                <input
                                    type="text"
                                    name="website"
                                    placeholder="Enter Website link"
                                    className={"form-control" + (errors.website && touched.website ? " is-invalid" : "")}
                                    value={values.website}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="Website"
                                />
                                {errors.website && touched.website ? <span className="form-error">{errors.website}</span> : null}
                            </div>
                            <div className="col-md-12 mb-3">
                                <label className="form-label">About Us</label>
                                <textarea
                                    type="text"
                                    name="aboutUs"
                                    placeholder="Enter About Us"
                                    className={"form-control textarea-box " + (errors.aboutUs && touched.aboutUs ? " is-invalid" : "")}
                                    value={values.aboutUs}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="About Us"
                                />
                                {errors.aboutUs && touched.aboutUs ? <span className="form-error">{errors.aboutUs}</span> : null}
                            </div>                            
                            <div className="col-md-12 text-center mt-4 btn-group-box">
                                <button disabled={!formik.dirty} type="submit" className="btn btn-secondry max-w">Save</button>
                            </div>
                        </div>
                    </Form>
                </FormikProvider>
            </div>
        </div>
    )
}
