  import React, { useEffect, useState } from "react";
  import { Link, useNavigate, useParams } from "react-router-dom";
  import {
    changeStatusPublished,
    getOneCouponPublished,
  } from "../../../Services/CoupanServices";
  import { MEDIA_BASE_URL } from "../../../Utils/Constants";
  import logo from "../../../Assets/images/logo.png";
  import { tostE, tostS } from "../../../Utils/Toast";
  import moment from "moment";
import { formatDate1, getDateForMenu } from "../../../Utils/CommonFunctions";

  export default function PublishCouponDetails() {
    const navigate = useNavigate();
    const { id } = useParams(); // Retrieve the ID from URL params
    const [loading, setLoading] = useState(false); // State for loader
    const [refresh, setRefresh] = useState(false);
    const [couponDetails, setCouponDetails] = useState(null);
    const [startTime, setStartTime] = useState(null); // Start time of update process

    useEffect(() => {
      setStartTime(new Date()); // Record start time
      setLoading(true); // Show loader before making API call
      const fetchCouponDetails = async () => {
        try {
          const response = await getOneCouponPublished(id);
          if (response.status === 200) {
            setTimeout(() => {
              setLoading(false); // Hide loader after API call completes
              setStartTime(null); // Reset start time
              setCouponDetails(response.data.data); // Assuming response.data contains coupon details
            }, 1000);
          } else {
            // Handle error case
            setLoading(false); // Hide loader after API call completes
            setStartTime(null); // Reset start time
            console.error("Failed to fetch coupon details:", response.statusText);
          }
        } catch (error) {
          setLoading(false); // Hide loader after API call completes
          setStartTime(null); // Reset start time
          console.error("Error fetching coupon details:", error);
        }
      };

      fetchCouponDetails();
    }, [id, refresh]);
    const handleChangeStatus = () => {
      // Toggle the status between 0 and 1
      // const newStatus = couponDetails?.status === 0 ? 1 : 0;

      // Update the status in the couponDetails state
      // setCouponDetails(prevState => ({
      //     ...prevState,
      //     status: newStatus
      // }));

      // Prepare payload for API call
      const payload = {
        id: id,
        status: couponDetails?.status == 0 ? 1 : 0,
      };

      // Call the API to update the status
      changeStatusPublished(payload)
        .then((res) => {
          if (res.status === 200) {
            tostS(res.data.message);
            setRefresh(!refresh);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data) {
            tostE(err?.response?.data?.message);
          }
        });
    };
    const formatDate = (
      slotStartTime,
      duration,
      durationText,
    ) => {
      if (!slotStartTime) {
        return ''; // Handle the case when slotStartTime is undefined
      }
      
      const [startHour, startMinute] = slotStartTime.split(':').map(Number);
      let startTotalMinutes = startHour * 60 + startMinute;
      if (durationText === 'Hours') {
        duration *= 60;
      }
      let endTotalMinutes = startTotalMinutes + duration;
      let endHour = Math.floor(endTotalMinutes / 60) % 24;
      let endMinute = endTotalMinutes % 60;
      let formattedStartTime = formatTime(startHour, startMinute);
      let formattedEndTime = formatTime(endHour, endMinute);
    
      return `${formattedStartTime}-${formattedEndTime}`;
    };
    
    
    function formatTime(hour, minute) {
      let amPM = hour >= 12 ? 'PM' : 'AM';
      let formattedHour = hour % 12 === 0 ? 12 : hour % 12;
      let formattedMinute = minute < 10 ? '0' + minute : minute;
      return `${formattedHour}:${formattedMinute}${amPM}`;
    }
    
    // const formatDate = (dateString) => {
    //   if (!dateString) return ''; // Handle the case when dateString is undefined
    //   const date = new Date(dateString);
    //   return moment(date).format('DD MMM');
    // };
    
    
    return (
      <>
        {loading && (
          <div className="preload">
            <img src={logo} alt="Insta-coupon Logo" className="preloadlogo" />
          </div>
        )}

        {!loading ? (
          <div className="coupon-sec-detail section-space">
            <div className="container">
              <div className="coupon-sec-detail-top-head mb-4 d-flex align-items-center justify-content-between ">
                <div className="coupan-sec-top-head-left">
                  <div className="page-title ">
                    <h1>Published Coupon Detail</h1>
                  </div>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/merchant/my-coupon/published-coupon/list">
                          My Coupons
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to={`/merchant/my-coupon/published-coupon/list`}>
                          Published Coupons
                        </Link>
                      </li>                   
                      <li className="breadcrumb-item active" aria-current="page">
                        Published Coupon Detail 
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="coupan-sec-top-head-right d-flex align-items-center">
                  <div className="switch-toggle-sm">
                    <label className="switch">
                      <input
                        onChange={() => {
                          handleChangeStatus();
                        }}
                        type="checkbox"
                        checked={couponDetails?.status == 1}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <ul className="action-info d-flex align-items-center">
                    <li className="edit-action">
                      <Link
                        to={`/merchant/my-coupon/published-coupon/update/${id}`}
                      >
                        <i className="ri-edit-line"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="coupon-sec-detail-main">
                <div className="coupan-box-detail d-flex align-items-center mb-4">
                  <figure>
                    <img src={MEDIA_BASE_URL + couponDetails?.couponImage} />
                  </figure>
                  <figcaption>
                    <h4>{couponDetails?.title}</h4>
                    <div className="date-box mb-2">
                      <i className="ri-calendar-line"></i>
                     {formatDate1(couponDetails?.endDateTime)}{', '}
                     {formatDate(couponDetails?.slotStartTime, couponDetails?.duration, couponDetails?.durationText)}
                    </div>
                    <div className="price-box">
                    List Price :&nbsp;
                      <b className="price-text">${couponDetails?.price}</b>
                    </div>
                    <div className="price-box">
                     Category :&nbsp;
                      <b className="price-text">{couponDetails?.categoryName}</b>
                    </div>
                  </figcaption>
                </div>

                <div className="coupon-sec-detail-content mb-4">
                  <h4>Discounted Price</h4>
                  <p>${couponDetails?.discountedPrice}</p>
                </div>    
                <div className="coupon-sec-detail-content mb-4">
                  <h4>Discount Percentage</h4>
                  <p>{couponDetails?.disPercentage}% off</p>
                </div>
                <div className="coupon-sec-detail-content mb-4 coupon-sec-des">
                    <h4>Number of Slots </h4>
                    <p>{couponDetails?.numberOfSlots}</p>
                </div>   
                <div className="coupon-sec-detail-content mb-4 coupon-sec-des">
                    <h4>Start Date </h4>
                    <p>{formatDate1(couponDetails?.startDateTime)}</p>
                    {/* <p>{couponDetails ? formatDate(couponDetails.startDateTime) : ''}</p> */}
     
                     {/* <p>{console.log(moment(getDateForMenu(couponDetails?.startDateTime)),'ss')}</p> */}
                </div>                                             
                <div className="coupon-sec-detail-content mb-4 coupon-sec-des">
                  <h4> {couponDetails?.dailyShow == false && couponDetails?.weeklyShow == null ?   null  : 'Show'}</h4>
                    <p>{couponDetails?.dailyShow == true ? 'Daily' : 
                   couponDetails?.weeklyShow?.map((item,index)=>`${item}${couponDetails?.weeklyShow.length-1==index?" ":", "}`)}</p>
                </div>       

                <div className="coupon-sec-detail-content mb-4 coupon-sec-des customdescription-box">
                  <h4>Description</h4>
                  <p>{couponDetails?.description}</p>
                </div>
                <div className="coupon-sec-detail-content mb-4 coupon-se-gallery">
                  <h4>Gallery</h4>
                  <ul className="gallery-img-list d-flex">
                    {couponDetails?.galleryImages?.map((image, index) => (
                      <li key={index}>
                        <figure>
                          <img
                            key={index}
                            src={MEDIA_BASE_URL + image}
                            alt={`Image ${index + 1}`}
                          />
                        </figure>
                        <a className="close-img">
                          <i className="ri-close-fill"></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
