import React from 'react'
import chartImage from '../../../Assets/images/line-chart.png'
import GrowthChart from '../../../Components/Dashboard/GrowthChart'

export default function DashboardScreen() {
  return (
    <div className="dashboard-sec section-space">
      <div className="container">
        <div className="page-title mb-4">
          <h1>Dashboard</h1>
        </div>
        <div className="dashboard-info-main">
          <div className="row">
            <div className="col-md-3">
              <div className="dashboard-info d-flex justify-content-between align-items-center yallow-dash-info">
                <div className="dashboard-info-left">
                  <h3>$100</h3>
                  <p>Active Coupons</p>
                </div>
                <div className="dashboard-info-right">
                  <span><i className="ti ti-ticket"></i></span>
                </div>
              </div>
              <div className="backshadow"></div>           
            </div>
            <div className="col-md-3">
              <div className="dashboard-info d-flex justify-content-between align-items-center green-dash-info">
                <div className="dashboard-info-left">
                  <h3>$100</h3>
                  <p>Used Coupons</p>
                </div>
                <div className="dashboard-info-right">
                  <span><i className="ti ti-ticket"></i></span>
                </div>
              </div>
              <div className="backshadow green-shadow"></div> 
            </div>
            <div className="col-md-3">
              <div className="dashboard-info d-flex justify-content-between align-items-center pink-dash-info">
                <div className="dashboard-info-left">
                  <h3>$100</h3>
                  <p>Unredeemed Coupons</p>
                </div>
                <div className="dashboard-info-right">
                  <span><i className="ti ti-ticket"></i></span>
                </div>
              </div>
              <div className="backshadow pink-shadow" ></div>
            </div>
            <div className="col-md-3">
              <div className="dashboard-info d-flex justify-content-between align-items-center blue-dash-info">
                <div className="dashboard-info-left">
                  <h3>130K</h3>
                  <p>Customers Acquired</p>
                </div>
                <div className="dashboard-info-right">
                  <span><i className="ti ti-ticket"></i></span>
                </div>
              </div>
              <div className="backshadow blue-shadow"></div> 
            </div>
          </div>
        </div>
        <div className="box-shadow mt-5">
          <div className="section-title">
            <h2>Growth Chart</h2>
            <p>Your Business Growth month by month</p>
          </div>
          <div className="graph-box p-4">
            <GrowthChart />
          </div>
        </div>
      </div>
    </div>
  )
}
