import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { changeStatus, deleteCouponTemplate, getCouponDetails } from '../../../Services/CoupanServices';
import { MEDIA_BASE_URL } from '../../../Utils/Constants';
import logo from '../../../Assets/images/logo.png'
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { tostE, tostS } from '../../../Utils/Toast';

export default function CouponDetail() {
    const navigate = useNavigate();
    const { id } = useParams(); // Retrieve the ID from URL params
    const [loading, setLoading] = useState(false); // State for loader
    const [show, setShow] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [couponDetails, setCouponDetails] = useState(null);
    const [startTime, setStartTime] = useState(null); // Start time of update process
    
    useEffect(() => {
        setStartTime(new Date()); // Record start time
        setLoading(true); // Show loader before making API call  
        const fetchCouponDetails = async () => {     
            try {                
                const response = await getCouponDetails(id);
                if (response.status === 200) {
                    
                    setTimeout(() => {
                        setLoading(false); // Hide loader after API call completes
                        setStartTime(null); // Reset start time
                       setCouponDetails(response?.data?.data); // Assuming response.data contains coupon details
                    }, 1000); 

                } else {
                    // Handle error case
                    setLoading(false); // Hide loader after API call completes
                    setStartTime(null); // Reset start time
                    console.error('Failed to fetch coupon details:', response.statusText);
                }
            } catch (error) {
                setLoading(false); // Hide loader after API call completes
                setStartTime(null); // Reset start time
                //console.error('Error fetching coupon details:', error);
            } finally {
                setTimeout(() => {
                    setLoading(false); // Hide loader after API call completes
                    setStartTime(null); // Reset start time
                }, 1000);}
        };
        fetchCouponDetails();
    }, [id, refresh]);

    const handlePublish = () => {
        navigate(`/merchant/my-coupon/coupon-template/publish/${id}`);
    };

    const handleClose = () => {
        setShow(false)
    }

    const handleDelete = () =>{
        setStartTime(new Date()); // Record start time
        setLoading(true); // Show loader before making API call
        deleteCouponTemplate(id).then((res)=>{
            if(res.status===200){
                setTimeout(() => {
                    setLoading(false); // Hide loader after API call completes
                   // setStartTime(null); // Reset start time
                   tostS(res.data.message);
                   navigate("/merchant/my-coupon/coupon-template/list");
                }, 1000);
            }
        }).catch((err)=>{
            if (err?.response?.data) {
                tostE(err?.response?.data?.message);
            }
        }).finally(() => {
            setTimeout(() => {
                setLoading(false); // Hide loader after API call completes
                setStartTime(null); // Reset start time
            }, 1000);
        });
    }

    const handleChangeStatus = () =>{
        const payload = {
            id,
            status : couponDetails?.status == 0 ? 1 : 0
        }
        changeStatus(payload).then((res)=>{
            if(res.status===200){
                tostS(res.data.message);
                setRefresh(!refresh);
            }
        }).catch((err)=>{
            console.log(err);
            if (err?.response?.data) {
                tostE(err?.response?.data?.message);
            }
        })
        
    }
    
return (
<>
    {loading && (
            <div className='preload'>
                <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
            </div>
    )}
    
    {!loading ? 
        <div className="coupon-sec-detail section-space">
            <div className="container">
                <div className="coupon-sec-detail-top-head mb-4 d-flex align-items-center justify-content-between ">
                    <div className="coupan-sec-top-head-left">
                        <div className="page-title ">
                            <h1>Coupon Template Detail</h1>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/merchant/my-coupon/published-coupon/list">My Coupons</Link></li>
                                <li className="breadcrumb-item"><Link to={`/merchant/my-coupon/coupon-template/list`}>Coupon Templates</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> Coupon Template Detail</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="coupan-sec-top-head-right d-flex align-items-center">
                    <div className="switch-toggle-sm">
                            <label className="switch">
                            <input onChange={() => {handleChangeStatus()}} type="checkbox" checked={couponDetails?.status == 1}/>
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <ul className="action-info d-flex align-items-center">
                            <li className="edit-action">
                                <Link to={`/merchant/my-coupon/coupon-template/update/${id}`}>
                                    <i className="ri-edit-line"></i>
                                </Link>
                            </li>                            
                            <li className="delete-action">
                                <button type="button" onClick={() => { setShow(true) }}>
                                    <i className="ri-delete-bin-5-line"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="coupon-sec-detail-main">
                    <div className="coupan-box-detail d-flex align-items-center mb-4">
                        <figure><img src={MEDIA_BASE_URL +  couponDetails?.couponImage} /></figure>
                        <figcaption>
                            <h4>{couponDetails?.title}</h4>
                            {/* <div className="date-box mb-2"><i className="ri-calendar-line"></i> {couponDetails?.duration} {couponDetails?.durationText}</div> */}
                            <div className="price-box">List Price :&nbsp;<b className="price-text">${couponDetails?.price}</b></div>
                            <div className="price-box">Category :&nbsp;<b className="price-text">{couponDetails?.categoryName}</b></div>
                        </figcaption>
                    </div>
                    <div className="coupon-sec-detail-content mb-4">
                  <h4>Discounted Price</h4>
                  <p>${couponDetails?.discountedPrice}</p>
                </div>  
                    <div className="coupon-sec-detail-content mb-4">
                        <h4>Discount Percentage</h4>
                        <p>{couponDetails?.disPercentage}% off</p>
                    </div>
                    <div className="coupon-sec-detail-content mb-4">
                        <h4>Duration</h4>
                        <p>{couponDetails?.duration} {couponDetails?.durationText}</p>
                    </div>
                    <div className="coupon-sec-detail-content mb-4 coupon-sec-des customdescription-box">
                        <h4>Description</h4>
                        <p>{couponDetails?.description}</p>
                    </div>
                    <div className="coupon-sec-detail-content mb-4 coupon-se-gallery">
                        <h4>Gallery</h4>
                        <ul className="gallery-img-list d-flex">
                        {couponDetails?.galleryImages.map((image, index) => (
                            <li key={index}>
                                <figure><img key={index} src={MEDIA_BASE_URL + image} alt={`Image ${index + 1}`}/></figure>
                                <a className="close-img"><i className="ri-close-fill"></i></a>
                            </li>
                        ))}
                        </ul>
                    </div>
                    {couponDetails?.status == 1 ? 
                    <div className=" mt-4 btn-group-box">
                        <button className="btn btn-secondry max-w" onClick={()=>{handlePublish()}}>Publish Coupon</button>
                    </div>
                    : null
                    }
                </div>
            </div>
            <Modal className="modal fade common-modal delete-box-style" show={show} backdrop="static" onHide={handleClose}>                
                <div className="modal-head text-center mb-2">
                    <h1 className="modal-title " id="exampleModalLabel">Delete Coupon Template </h1>
                    <button type="button" onClick={()=>{handleClose()}} className="btn-close"  aria-label="Close"></button>
                </div>
                <ModalBody>
                    <div className="single-content-modal text-center">
                        <p>Are you sure you want to delete the coupon template?</p>
                    </div>
                </ModalBody>
                <div className="modal-foot mt-4">
                    <button onClick={()=>{handleClose()}} className="btn  btn-primary min-w">No</button>
                    <button className="btn btn-secondry min-w ms-2" onClick={()=>{handleDelete()}}>Yes, Delete</button>
                </div>
            </Modal>
        </div>
        :null 
    }
</>        
    )
}
