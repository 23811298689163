import React, { useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup';
import { changePassword } from '../../Services/MyAccountServices';
import { tostE, tostS } from '../../Utils/Toast';
import { PASSWORD_COMPLEX_REGEX } from '../../Utils/Constants';
import logo from '../../Assets/images/logo.png'

export default function ChangePassword() {
    const [loading, setLoading] = useState(false); // State for loader
    const [startTime, setStartTime] = useState(null); // Start time of update process

    const changePassValidation = Yup.object().shape({
        currentPassword: Yup.string().required('Please enter current password'),
        newPassword: Yup.string().required('Please enter new password').min(8, "Password must be 8 characters long alphanumeric containing at least 1 uppercase letter and 1 special character.")
        .matches(PASSWORD_COMPLEX_REGEX, "Password must be 8 characters long alphanumeric containing at least 1 uppercase letter and 1 special character."),
        confirmPassword: Yup.string().required('Please enter confirm Password').test('passwords-match', 'Password does not match', function(value){
            return this.parent.newPassword === value
        })
    });

    const formik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: ""
        },
        validationSchema: changePassValidation,
        onSubmit: async (values, {resetForm}) => {
            setStartTime(new Date()); // Record start time
            setLoading(true); // Show loader before making API call
            const payload = {
                currentPassword: values.currentPassword,
                newPassword: values.newPassword,
                confirmPassword: values.confirmPassword
            }
            changePassword(payload).then((res) => {
                if (res.status == 200) {
                    setTimeout(() => {
                        setLoading(false); // Hide loader after API call completes
                       // setStartTime(null); // Reset start time
                       tostS(res?.data?.message);
                       resetForm();
                    }, 2000);


                }
            }).catch((err) => {
                console.log(err);
                if (err?.response?.data) {
                    tostE(err?.response?.data?.message);
                }
            }).finally(() => {
                // Simulate 2 seconds delay
                setTimeout(() => {
                    setLoading(false); // Hide loader after API call completes
                    setStartTime(null); // Reset start time
                }, 2000);
            });
        }
    })

    const { errors, values, touched, handleSubmit, handleChange, handleBlur } = formik;

    return (
        <div className="tab-pane fade show active">
            {loading && (
                <div className='preload'>
                    <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
                </div>
            )}
            <div className="box-shadow">
                <div className="my-account-right-head mb-4">
                    <div className="section-title ">
                        <h2>Change Password</h2>
                    </div>
                </div>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Current Password</label>
                                <input
                                    type="password"
                                    name="currentPassword"
                                    placeholder="Enter current password"
                                    className={"form-control" + (errors.currentPassword && touched.currentPassword ? " is-invalid" : "")}
                                    value={values.currentPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="Current Password"
                                    autoComplete='off'
                                />
                                {errors.currentPassword && touched.currentPassword ? <span className="form-error">{errors.currentPassword}</span> : null}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">New Password</label>
                                <input
                                    name="newPassword"
                                    type="password"
                                    placeholder="Enter new password"
                                    className={"form-control" + (errors.newPassword && touched.newPassword ? " is-invalid" : "")}
                                    value={values.newPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="New Password"
                                    autoComplete='off'
                                />
                                {errors.newPassword && touched.newPassword ? <span className="form-error">{errors.newPassword}</span> : null}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Confirm New Password</label>
                                <input
                                    name="confirmPassword"
                                    type="password"
                                    placeholder="Confirm password"
                                    className={"form-control" + (errors.confirmPassword && touched.confirmPassword ? " is-invalid" : "")}
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="New Password"
                                    autoComplete='off'
                                />
                                {errors.confirmPassword && touched.confirmPassword ? <span className="form-error">{errors.confirmPassword}</span> : null}
                            </div>

                            <div className="col-md-12 text-center mt-4 btn-group-box">
                                <button className="btn btn-secondry max-w">Save</button>
                            </div>
                        </div>
                    </Form>
                </FormikProvider>
            </div>
        </div>
    )
}
