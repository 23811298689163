import AxiosInterceptor from "../Utils/AxiosInterceptor";

const PREFIX = "access/";

export const getCategories = async () => {
    return await AxiosInterceptor().get(PREFIX + `getCategories`);
};

export const signUpService = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `signUp`, payload);
};

export const phoneVerifyService = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `phoneVerify`, payload);
};

export const resendPhoneOtp = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `resendPhoneOtp`, payload);
};

export const sendPhoneOtp = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `sendPhoneOtp`, payload);
};

export const emailVerifyService = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `emailVerify`, payload);
};

export const resendEmailOtp = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `resendEmailOtp`, payload);
};

export const sendEmailOtp = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `sendEmailOtp`, payload);
};

export const forgotPassword = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `forgotPassword`, payload);
};

export const resetPassword = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `resetPassword`, payload);
};

export const login = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `login`, payload);
};

export const loginViaOtp = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `loginViaOtp`, payload);
};