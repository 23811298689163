import moment from "moment-timezone";
export const InitialRender = (data) => {
    if (data != null && data != undefined && data) {
      
      let tempArr = data;
      let initials = "";
      tempArr.map((i) => {
        if (i) {
          initials = initials + i[0];
        }
      });
      initials = initials.length > 2 ? initials.slice(0, 2).toUpperCase() : initials.toUpperCase();
      return initials;
    }
    return "NA";
  };


const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const timezone = systemTimeZone;
export function getDateForMenu(dateStr = null) {
  let date;
  if (dateStr) {
    date = moment.tz(dateStr, timezone);
  } else {
    date = moment.tz(timezone);
  }
  const isDST = date.isDST();
  const isDSTNow = moment(new Date()).isDST();
  
 if (isDST && !isDSTNow) {
   date.subtract(1, "hour");
 } else if (!isDST && isDSTNow) {
   date.add(1, "hour");
 }

  return date.toDate();
}



export const TextLimitShow = (description, limit) => {
  const words = description?.split(' ');
  if (words.length > limit) {
    return words.slice(0, limit).join(' ') + '...';
  }
  return description;
};

 export const formatDate1 = (dateString) => {
  if (!dateString) return ''; // Handle the case when dateString is undefined or null

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    // If the date is invalid, return an empty string or handle the error as needed
    return '';
  } else {
    const day = String(date.getUTCDate()).padStart(2, '0');
    const monthIndex = date.getUTCMonth();
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const month = monthNames[monthIndex];
    return `${day} ${month}`;
  }
};
