import React, { useEffect, useState } from 'react'
import businessConfigImg from '../../../Assets/images/busi-conf-img.png'
import { Link } from 'react-router-dom'
import { getBusinessDetails } from '../../../Services/Businessconfig'
import logo from '../../../Assets/images/logo.png'
import { MEDIA_BASE_URL } from '../../../Utils/Constants'
import moment from 'moment'
import { Avatar } from '@mui/material'
export default function BusinessConfigDetail() {

   
    const [show, setShow] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [businessdetails, setBusinessdetails] = useState(null);
    const [loading, setLoading] = useState(false); // State for loader
    const [startTime, setStartTime] = useState(null); // Start time of update process
    const [routingnumber, setRoutingnumber] = useState(''); 
    const [acountnumber, setAcountnumber] = useState(''); 
    
    useEffect(() => {
        setStartTime(new Date()); // Record start time
        setLoading(true); // Show loader before making API call  
        const fetchCouponDetails = async () => {     
            try {                
                const response = await getBusinessDetails();
                if (response.status === 200) {
                    
                    setTimeout(() => {
                        setLoading(false); // Hide loader after API call completes
                        setStartTime(null); // Reset start time
                        setBusinessdetails(response?.data?.data); // Assuming response.data contains coupon details

                        const routingNumber = response?.data?.data?.bankRoutingNumber;
                        const maskedRoutingNumber = routingNumber && routingNumber.length > 4 ? 'XXXXXXXX-' + routingNumber.substr(-4) : routingNumber;
                        setRoutingnumber(maskedRoutingNumber);

                        // const acountnumber1 = response?.data?.data?.bankAccountNumber;
                        // const maskedAcountnumber1 = acountnumber1 && acountnumber1.length > 4 ? 'XXXXXXXX-' + acountnumber1.substr(-4) : routingNumber;
                        // setAcountnumber(maskedAcountnumber1);
                     
                    }, 1000); 

                } else {
                    // Handle error case
                    setLoading(false); // Hide loader after API call completes
                    setStartTime(null); // Reset start time
                    console.error('Failed to fetch coupon details:', response.statusText);
                }
            } catch (error) {
                setLoading(false); // Hide loader after API call completes
                setStartTime(null); // Reset start time
                //console.error('Error fetching coupon details:', error);
            } finally {
                setTimeout(() => {
                    setLoading(false); // Hide loader after API call completes
                    setStartTime(null); // Reset start time
                }, 1000);}
        };
        fetchCouponDetails();
    }, []);


 
    return (
      <div className="business-configuration section-space">
        {loading && (
          <div className="preload">
            <img src={logo} alt="Insta-coupon Logo" className="preloadlogo" />
          </div>
        )}
        <div className="container">
          <div className="coupon-sec-top-head mb-4  ">
            <div className="page-title ">
              <h1>Business Configuration</h1>
            </div>
          </div>
          <div className="business-configuration-box d-flex align-items-center justify-content-between mb-4">
            <div className="business-configuration-left d-flex align-items-center business-config-img-size">
              <figure>
                {businessdetails?.businessImage? 
                  <img src={MEDIA_BASE_URL + businessdetails?.businessImage} alt="business-image" />
                  :
                   <Avatar className="avatar-preview businessdetails-custom"/>
                }
             
                
              </figure>
              <figcaption>
                <h4>{businessdetails?.businessName ? businessdetails?.businessName : ''}</h4>
                <span>{businessdetails?.address ? businessdetails?.address+', ' : ''} {businessdetails?.city? businessdetails?.city+', ' : ''} {businessdetails?.state ? businessdetails?.state :''}{businessdetails?.zipCode ? ', '+businessdetails?.zipCode : ''}</span>
              </figcaption>
            </div>
            <div className="business-configuration-right ">
              <Link
                to="/merchant/business-config/update"
                className="btn btn-secondry min-sm-w"
              >
                Edit Details
              </Link>
            </div>
          </div>
          <div className="bus-conf-content mb-3">
            <h4>Business Details</h4>
            <p>
              <b>Business Hours: </b> 
              {/* {businessdetails?.businessHourStartTime && businessdetails?.businessHourEndTime ? 
              `${moment(businessdetails?.businessHourStartTime, 'HH:mm:ss').format('hh:mm a')} -    
              ${moment(businessdetails?.businessHourEndTime, 'HH:mm:ss').format('hh:mm a')}`
              : ""} */}

              {businessdetails?.businessHourStartTime ? 
               moment(businessdetails?.businessHourStartTime, 'HH:mm:ss').format('hh:mm a') : '08:00 am'} 
               {' - '} 
              {businessdetails?.businessHourStartTime && businessdetails?.businessHourEndTime ? 
               moment(businessdetails?.businessHourEndTime, 'HH:mm:ss').format('hh:mm a')
               : '08:00 pm'}
            </p>
            <p>
              <b>Contact Number: </b>
              {businessdetails?.businessContactNumber ? businessdetails?.businessContactNumber : ''}
            </p>
            <span>
              <b>Text Notification:  </b> 
              { String(businessdetails?.notificationText ? 'On' : 'Off')}
            </span>
            <span>
              <b>Email Notification: </b> {String(businessdetails?.notificationEmail ? 'On' : 'Off')}
            </span>
            <p>
              <b>Lead Time: </b>
              {businessdetails?.leadTime ? businessdetails?.leadTime+' minutes' :  '5  minutes'} 
            </p>
            <p>
              <b>Policy: </b> <br />
              {businessdetails?.policy ? businessdetails?.policy : ''}
            </p>
          </div>
          <div className="bus-conf-content mb-4 ">
            <h4>Category</h4>
            <p>
              {businessdetails?.categoryIds ?
               businessdetails?.categoryIds?.map((item,index)=>`${item.name}${businessdetails?.categoryIds.length-1==index?" ":", "}`)
              :''}</p>
          </div>
          <div className="bus-conf-content mb-4 ">
            <h4>Bank Account Detail</h4>
            <p>
              <b>Account Number: </b>
              {businessdetails?.bankAccountNumber ? `XXXXXXXX-` +businessdetails?.bankAccountNumber : ''}
            </p>
            <p>
              <b>Routing Number:</b> {routingnumber ? routingnumber : ''}
            </p>
          </div>
          <div className="bus-conf-content mb-4 business-address-11">
            <h4>Location</h4>
            <p>
              <b>Address: </b> {businessdetails?.address ? businessdetails?.address : ''}
            </p>
            <p>
              <span>
                <b>City:</b> {businessdetails?.city ? businessdetails?.city : ''}
              </span>
              <span>
                <b> State:</b> {businessdetails?.state ? businessdetails?.state :''}
              </span>
              <span>
                <b> Zip Code:</b> {businessdetails?.zipCode ? businessdetails?.zipCode : ''}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
}
