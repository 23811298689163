import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import couponImg from '../../../Assets/images/coupan.png';
import calenderImg from '../../../Assets/images/calnder-img.png';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { Calendar } from '@fullcalendar/core';
import { toMoment } from '@fullcalendar/moment';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid'; 
import { getCouponTemplateList, redeemCoupon } from '../../../Services/CoupanCalenderViewServices';
import { tostE, tostS } from '../../../Utils/Toast';
import { MEDIA_BASE_URL } from '../../../Utils/Constants';
import { getCalendarDetails } from '../../../Services/CoupanCalenderViewServices';
import moment from 'moment';
import { TextLimitShow, getDateForMenu } from '../../../Utils/CommonFunctions';
import logo from '../../../Assets/images/logo.png'
export default function CouponCalenderViewNew() {
  let i;
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupleftclick, setShowPopupleftclick] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showPopuanpublish, setShowPopuppublish] = useState(false);
  const [showBookappoinment, setShowBookappoinment] = useState(false);
  const [currentDate, setCurrentDate] = useState('');
  const [currentView, setCurrentView] = useState('timeGridDay');
  const calendarInstanceRef = useRef(null); 
  const [selectedCouponId, setSelectedCouponId] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [calendardetails, setCalendarDetails] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [dateAndTime, setDateAndTime] = useState(null);
  const calendarRef = useRef(null);
  const [loading, setLoading] = useState(false); // State for loader
  const [startTime, setStartTime] = useState(null); // Start time of update process
  const [slotMinTime, setSlotMinTime] = useState('08:00:00');
  const [slotMaxTime, setSlotMaxTime] = useState('20:30:00');
  const [redeemValue, setRedeemValue] = useState('');
  const [isInputEmpty, setIsInputEmpty] = useState(false);
  const [isInputInvalid, setIsInputInvalid] = useState(false);
  const [redeemedSuccessfully, setRedeemedSuccessfully] = useState(false);

  const navigate = useNavigate();
  const now = new Date();
  const hours = now.getHours();
  
  

  const handlePublicCouponTemplate = () => {
    navigate(`/merchant/my-coupon/coupon-template/list`);
  };

  const handleEditCoupon = () => {
    navigate(`/merchant/my-coupon/coupon-template/update/${selectedCouponId}`);
  };
  
  const handlePublishCoupon = () => {
    navigate(`/merchant/my-coupon/coupon-template/publish/${selectedCouponId}`, {
      state: { dateAndTime: dateAndTime }
    });
  };

  const handleViewChange = (view) => {
    setCurrentView(view);
  };


  const fetchCalendarDetails = async (date) => { 
    try {
      const response = await getCalendarDetails(date);
      if (response?.status === 200) {
        setTimeout(() => {
          setLoading(false); // Hide loader after API call completes
        const responseData = response?.data?.data;
        setCalendarDetails(responseData);
        if(responseData?.businessHourStartTime){
          const slotstarttime = responseData?.businessHourStartTime;
          setSlotMinTime(slotstarttime);
        }
        if(responseData?.businessHourEndTime){
          const slotEndTime = moment(responseData?.businessHourEndTime, 'HH:mm:ss');
          const slotEndTimeWithExtra = slotEndTime.add(30, 'minutes').format('HH:mm:ss');
          setSlotMaxTime(slotEndTimeWithExtra);
        }
      },500);
      }
    } catch (error) {
     // toastError(error?.response?.data?.message);
    } finally{

      setTimeout(() => {
        setLoading(false); // Hide loader after API call completes
        //setStartTime(null); // Reset start time
      }, 1000);
    };
  };
  // let formattedDate;
  // let toDateformate;

  // if(currentView=="timeGridDay"){
  //   formattedDate = moment(currentDate).format('YYYY-MM-DD');
  //   toDateformate= moment(currentDate).format('YYYY-MM-DD');
  // }else if(currentView=="timeGridWeek"){
  //   var currentDate = moment();
  //   var startOfWeek = moment(currentDate).startOf('week').add(0, 'days').format('YYYY-MM-DD');
  //   var endOfWeek = moment(currentDate).endOf('week').format('YYYY-MM-DD');
  //   formattedDate = startOfWeek;
  //   toDateformate = endOfWeek;
  // } else{
  //   formattedDate = moment(currentDate).startOf('month').format('YYYY-MM-DD');
  //   toDateformate = moment(currentDate).endOf('month').format('YYYY-MM-DD');
  // } 

  const formattedDate = moment(currentDate).format('YYYY-MM-DD');
  const date = {
    
    fromDate: formattedDate,
    toDate: formattedDate
  };
  
  useEffect(() => {
    setStartTime(new Date()); // Record start time
    setLoading(true); // Show loader before making API call
    fetchCalendarDetails(date);
    getEventsForDate()

    if (redeemedSuccessfully) {
      setShowPopup(false);
      fetchCalendarDetails(date)
    }
  }, [currentDate , redeemedSuccessfully]);



  useEffect(() => {
    getCouponTemplateList().then((res) => {
      if (res.status === 200) {
        const resData = res.data.data;
        setCategoryList(resData)
      }
    }).catch((err) => {
      tostE(err?.response?.data?.message);
    })
  }, [])


  const getEventsForDate = () => {
    const events = calendardetails?.finalResult?.map(item => {
      let Active = moment(item?.startDateTime, 'YYYY-MM-DD HH:mm:ss');
      let Purchased = moment(item?.redeemDate, 'YYYY-MM-DD HH:mm:ss');
      let slotStartTime = moment(item?.slotStartTime, 'HH:mm:ss');      
      let hours = slotStartTime.hours(); 
      let minutes = slotStartTime.minutes(); 

      if (minutes <= 29) {  
        minutes = '00';
      } else{
        minutes = '30';
      }
      if(hours<=9){
        hours=`0${hours}`;
      }
      
      let startdate;
      if(item?.type==="Active"){
        startdate = Active?.format('YYYY-MM-DD') + 'T' + hours + ':' + minutes + ':00';
      } else if(item?.type==="Purchased"){   
        startdate = Purchased?.format('YYYY-MM-DD') + 'T' + hours + ':' + minutes + ':00';
      }
      else if(item?.type==="Redeemed"){   
        startdate = Purchased?.format('YYYY-MM-DD') + 'T' + hours + ':' + minutes + ':00';
      } else if(item?.type==="Expired"){   
        startdate = Purchased?.format('YYYY-MM-DD') + 'T' + hours + ':' + minutes + ':00';
      }

      return {
        title: item?.title + ` (${item.numberOfSlots ? item?.numberOfSlots : item?.qty})`,
        titlepopup: item?.title,
        start: startdate, 
        end:startdate,
        type: item?.type,
        slotStartTime:item?.slotStartTime,
        couponid: item?.id,
        numberOfSlots: item?.numberOfSlots,
        qty:item?.qty,
        allRedeemed:item?.allRedeemed,
        allExpired:item?.allExpired,
      };
    });
    //console.log(events)
    return events;
};

 useEffect(() => {
  const calendarEl = calendarRef.current;  
  if (calendarEl) {
    const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const calendar = new Calendar(calendarEl, {
      plugins: [momentTimezonePlugin, dayGridPlugin, listPlugin, timeGridPlugin],
      timeZone: systemTimeZone,
      initialView: currentView,
      slotLabelInterval: '00:30:00',
      slotMinTime: slotMinTime,
      slotMaxTime: slotMaxTime,
      slotDuration: '00:30:00', 

      slotLabelFormat: {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      },
      headerToolbar: {
        left: '',
        center: '',
        right: ''
      },
      customButtons: {
        customPrev: {
          text: 'Prev', 
          click: function () {
            calendarInstanceRef.current.prev(); 
          }
        },
        customNext: {
          text: 'Next',
          click: function () {
            calendarInstanceRef.current.next(); 
          }
        }
      },
      views: {
        custom: {
          type: 'list',
          buttonText: 'View',
          duration: { days: 1 },
          options: [
            { text: 'Month', value: 'dayGridMonth' },
            { text: 'Week', value: 'timeGridWeek' },
            { text: 'Day', value: 'timeGridDay' }
          ]
        }
      },
      datesSet: (info) => {
        setCurrentDate(info.view.title);
      },      
      events: getEventsForDate(),
      eventClick: handleEventClick,
      eventClassNames: getEventClassNames,
      // firstDay: 1 
    });
    
    calendar.render();
    calendarInstanceRef.current = calendar;
    calendarEl.addEventListener('click', handleCalendarClick);
   }

  return () => {
    if (calendarInstanceRef.current) {
      calendarInstanceRef.current.destroy();
    }
    if (calendarEl) {
      calendarEl.removeEventListener('click', handleCalendarClick);
    }
  };
}, [currentView , slotMinTime, slotMaxTime]);



const getEventClassNames = (arg) => {
 
  const classNames = [];
  
  if (arg.event.extendedProps.type === 'Active') {
    classNames.push('active-event'); 
  } 
  else if (arg.event.extendedProps.type === 'Purchased') {
    classNames.push('purchased-event');
  } else if (arg.event.extendedProps.type === 'Redeemed') {
    classNames.push('redeemed-event');
  }else if (arg.event.extendedProps.type === 'Expired') {
    classNames.push('expired-event');
  }
  return classNames;
};

const updateCalendarEvents = () => {
  const calendar = calendarInstanceRef.current;
  if (calendar) {
    calendar.setOption('events', getEventsForDate());
  }
};

useEffect(() => {
  updateCalendarEvents();
}, [currentDate , currentView ,getEventClassNames , getEventsForDate]);

  function handleCalendarClick(event) {
    const clickedCell = event.target.closest('.fc-daygrid-day,  .fc-timeGridWeek-view, .fc-timegrid-slot');
  
    if (clickedCell) {
      const dateStr = clickedCell.getAttribute('data-date');
      const clickedDate = new Date(dateStr);
      const events = calendarInstanceRef.current.getEvents();


    const currentDate = new Date();

      if (clickedCell.classList.contains('fc-daygrid-day')) {

        const eventsAtDate = events.filter(event => isSameDay(event.start, clickedDate));
  
        if (eventsAtDate.length === 0 && event.detail === 2) {
            if (clickedDate < currentDate) {
              return; 
            }
            setShowPopupleftclick(true)
            const dateAndTime = {
              date: clickedDate.toLocaleDateString(),
            //  time: 'All day'
            };
            setDateAndTime(dateAndTime);
           
        }

      } else if (clickedCell.classList.contains('fc-timegrid-slot')) {

        
        const timeStr = clickedCell.getAttribute('data-time');
        const clickedTime = timeStr.split(':');
  
         const cellIndex = Array.from(clickedCell.parentElement.children).indexOf(clickedCell);
        const dateCells = document.querySelectorAll('.fc-day[data-date]');
        const dateCell = dateCells[cellIndex];
      
        const slotDateStr = dateCell.getAttribute('data-date');
        const slotDate = getDateForMenu(slotDateStr);
        
         const clickedDateTime = new Date(slotDate.getFullYear(), slotDate.getMonth(), slotDate.getDate(), clickedTime[0], clickedTime[1]);
     
        const currentTime = new Date();

      

        if (clickedDateTime < currentTime) {
          return; 
        }

        const eventsAtDateTime = events.filter(event => {
          const eventStart = event.start;
          const eventEnd = event.end;
  
          return (
            eventStart <= clickedDateTime &&
            eventEnd >= clickedDateTime
          );
        });
  
        if (eventsAtDateTime.length === 0 && event.detail === 2) {
          setShowPopupleftclick(true)
          const dateOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
          const dateAndTime = {
            date: clickedDateTime.toLocaleDateString('en-US', dateOptions),
            time: clickedDateTime.toLocaleTimeString(), 
            dateTime : clickedDateTime
          };
          setDateAndTime(dateAndTime);
         }
      }
      else {
        console.log("fc-timeGridWeek-view")
      }

    }
  }

    
  function isSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }


  const handleEventClick = (info) => {
    if (info.event.title === 'No event') {
      setShowOptions(true);
    } else {
      setSelectedEvent(info.event);
      setShowPopup(true);
    }
  };

  const ShowPopuppublish = () => {
    setShowPopuppublish(true);
    setShowPopupleftclick(false);
  };


  const showBookappoinmentfun = () => {
    setShowBookappoinment(true);
    setShowPopupleftclick(false);
  };
  const closePopup = () => {
    setSelectedEvent(null);
    setShowPopup(false)
    setShowPopupleftclick(false);
    setShowPopuppublish(false);
    setShowBookappoinment(false);
    setIsInputEmpty(false);
    setIsInputInvalid(false);
    setRedeemValue('');
  };



  const handleRedeem = () => {
    if (redeemValue.trim() === '') {
      setIsInputEmpty(true);
      return;
    }
    if (!/^\d{6}$/.test(redeemValue.trim())) {
      setIsInputInvalid(true);
      return;
    }else{
      setIsInputInvalid(false);
    }

    const payload = {
      id:redeemValue,
    };    
    redeemCoupon(payload).then((res) => {
      if (res.status === 200) {
          navigate(`/merchant/my-coupon/published-coupon/calender/list`);
          tostS(res?.data?.message);
          setRedeemedSuccessfully(true);
      }
    }).catch((err) => {
      if (err?.response?.data) {
        tostE(err?.response?.data?.message);
      }
    })
  };

  return (
    <div className="coupan-sec-calender section-space">
       {loading && (
          <div className="preload">
            <img src={logo} alt="Insta-coupon Logo" className="preloadlogo" />
          </div>
        )}
      <div className="container">
        <div className="coupan-sec-top-head d-flex align-items-center justify-content-between mb-4">
          <div className="page-title ">
            <h1>My Coupons</h1>
          </div>
          <div className="coupan-sec-top-head-right d-flex align-items-center">
            <ul className="coupan-sec-list d-flex ms-3">
              <li>
                <Link to="/merchant/my-coupon/published-coupon/list">
                  <i className="ri-list-check"></i>
                </Link>
              </li>
              <li className="active">
                <Link to="/merchant/my-coupon/published-coupon/calender/list">
                  <i className="ri-calendar-line"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="coupan-sec-calender-info-main">
          <div className="dashboard-info-main">
            <div className="row">
              <div className="col-md-6">
                <div className="dashboard-info d-flex justify-content-between align-items-center yallow-dash-info">
                  <div className="dashboard-info-left">
                    <h3>{calendardetails?.activeCoupons}</h3>
                    <p>Active Coupons</p>
                  </div>
                  <div className="dashboard-info-right">
                    <span>
                      <i className="ti ti-ticket"></i>
                    </span>
                  </div>
                </div>
                <div className="backshadow"></div>
              </div>
              <div className="col-md-6">
                <div className="dashboard-info d-flex justify-content-between align-items-center green-clender-dash-info">
                  <div className="dashboard-info-left">
                    <h3>{calendardetails?.purchaseCoupons}</h3>
                    <p>Purchased Coupons </p>
                  </div>
                  <div className="dashboard-info-right">
                    <span>
                      <i className="ti ti-ticket"></i>
                    </span>
                  </div>
                </div>
                <div className="backshadow green-clender-dash-info"></div>
              </div>

              <div className="col-md-6 coupan-calender-spacing">
                <div className="dashboard-info d-flex justify-content-between align-items-center pink-dash-info">
                  <div className="dashboard-info-left">
                    <h3>{calendardetails?.expiredCoupons}</h3>
                    <p>Expired Coupons</p>
                  </div>
                  <div className="dashboard-info-right">
                    <span>
                      <i className="ti ti-ticket"></i>
                    </span>
                  </div>
                </div>
                <div className="backshadow pink-shadow"></div>
              </div>
              
              <div className="col-md-6 coupan-calender-spacing">
                <div className="dashboard-info d-flex justify-content-between align-items-center green-light-dash-info">
                  <div className="dashboard-info-left">
                    <h3>{calendardetails?.redeemCoupons}</h3>
                    <p>Redeemed Coupons</p>
                  </div>
                  <div className="dashboard-info-right">
                    <span>
                      <i className="ti ti-ticket"></i>
                    </span>
                  </div>
                </div>
                <div className="backshadow green-light-dash-info-shadow"></div>
              </div>
            </div>
          </div>
          <div className="box-shadow mt-5">
            <div className="section-title mb-4">
              <h2>Popular Coupons</h2>
              <p>You can drag coupon to a particular time slot</p>
            </div>
            <div className="row">
              {[1, 2, 3].map(() =>
                <div className="col-md-4 coupan-box Popular-coupan-box" key={i+1}>
                  <div
                    className="box-shadow p-3 d-flex"
                  >
                    <figure>
                      <img src={couponImg} alt="coupon-img" />
                    </figure>
                    <figcaption>
                      <div className="coupan-content-head d-flex align-items-center justify-content-between pb-1">
                        <h4 className="pb-0">80% Off On Diwali Box</h4>
                        <b className="price-text">$100</b>
                      </div>
                      <span className="date-text">
                        <i className="ri-calendar-line"></i> 9th Dec, 9AM-10PM
                      </span>
                      <span className="discount-text mb-0">Discount of 10%</span>
                    </figcaption>
                  </div>
                </div>
              )}
            </div>
          </div>
          <br />
          <div className="calender-box mt-4">
            <figure>
              <div className="coupan-sec-calender section-space">
                <div id="calendar" class="calendar-custom fc fc-media-screen fc-direction-ltr fc-theme-standard">
                  <div class="fc-header-toolbar fc-toolbar fc-toolbar-ltr custom-toolbar-box">
                    <div class="fc-toolbar-chunk">
                      <button
                        type="button"
                        title="Previous month"
                        aria-pressed="false"
                        class="fc-prev-button fc-button fc-button-primary"
                        onClick={() => calendarInstanceRef.current.prev()}
                      >
                        <span class="fc-icon fc-icon-chevron-left" role="img"></span>
                      </button>
                    </div>
                    <div class="fc-toolbar-chunk">
                      <h2 class="fc-toolbar-title" id="fc-dom-1456">
                        {currentDate}
                      </h2>
                    </div>
                    <div class="fc-toolbar-chunk">
                      <div class="fc-button-group">
                        <button
                          type="button"
                          title="Next month"
                          aria-pressed="false"
                          class="fc-next-button fc-button fc-button-primary"
                          onClick={() => calendarInstanceRef.current.next()}
                        >
                          <span class="fc-icon fc-icon-chevron-right" role="img"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='custom-calender-view-header'>
                  <ul>
                    <li className='orange'><span>Active</span></li>
                    <li className='green'><span>Purchased</span></li>
                    <li className='red'><span>Expired</span></li>
                    <li className='darkgreen'><span>Redeemed</span></li>
                  </ul>
                </div>
                <div id="calendar" className='calendar-custom' ref={calendarRef}></div>
              </div>
            </figure>
          </div>
        </div>
      </div>



      <Modal className="modal fade common-modal delete-box-style" show={showPopup} backdrop="static">
        <ModalBody>
          <div className="modal-head text-center mb-2">
            <h1 className="modal-title" id="exampleModalLabel">Coupon Details</h1>
            <button type="button" onClick={closePopup} className="btn-close" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <ul className="coupon-detail-info">
              <li>
                <b>Title</b>
                <p>{selectedEvent?.extendedProps?.titlepopup}</p>
              </li>
              <li>
                <b>Type</b>
                <p>{selectedEvent?.extendedProps?.type}</p>
              </li>
              <li>
                {selectedEvent?.extendedProps?.numberOfSlots ?
                  <>
                    <b>Number Of Slots</b>
                    <p>{selectedEvent?.extendedProps?.numberOfSlots}</p>
                  </>
                  : null}

                {selectedEvent?.extendedProps?.qty ?
                  <>
                    <b>Number Of Slots</b>
                    <p>{selectedEvent?.extendedProps?.qty}</p>
                  </>
                  : null}
              </li>
              <li>
                <b>Slots Start Time</b>
                <p>{moment(selectedEvent?.extendedProps?.slotStartTime, 'HH:mm:ss').format('h:mm A') 
                }</p>
              </li>
              {selectedEvent?.extendedProps?.type === 'Purchased' ?
              <div className='redeemcoupan-box'>
                    {selectedEvent?.extendedProps?.allRedeemed === true ? (
                      <>
                        <b>Attention:</b> <span> Slots for Today Are Fully Redeemed</span>
                      </>
                    ) : selectedEvent?.extendedProps?.allExpired === true ? (
                      <>
                        <b>Attention:</b><span> Oops! It looks like the coupon purchase time has closed.</span>
                      </>
                    ) : (
                  
                      <>
                        <p>Coupon ID</p>
                  <input 
                    class="redeem-coupan-id-field"  
                    type="text"
                    id="redeem-value-id" 
                    value={redeemValue} 
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d{0,6}$/.test(inputValue)) {
                        setRedeemValue(inputValue);
                        setIsInputEmpty(false);
                        setIsInputInvalid(false);
                      }
                    }} 
                    pattern="[0-9]*" 
                    placeholder='Enter Coupon ID'
                  />                              
                  <br/>            
                   {isInputEmpty && <span className="form-error">This field is required.</span>}
                   {isInputInvalid &&  <span className="form-error">Invalid Coupon ID.</span>}
                   </>
                    )}
                    
              </div>
              : ''}
            </ul>
            
          </div>

          {selectedEvent?.extendedProps?.type === 'Purchased'?
              selectedEvent?.extendedProps?.allRedeemed === true ? (
                ''
              ) : selectedEvent?.extendedProps?.allExpired === true ? (
                ''
              ) : (
                <>
                <div className="modal-foot mt-2 text-center">
                  <button className="btn btn-secondry min-w ms-2" onClick={handleRedeem}>
                    Redeem
                  </button>
                </div>
              </>
              )
          :null }
        </ModalBody>
      </Modal>


      <Modal className="modal fade common-modal delete-box-style" show={showPopupleftclick} backdrop="static">
        <div className="modal-head text-center mb-2">
          <h1 className="modal-title " id="exampleModalLabel">Select Your Action </h1>
          <button type="button" onClick={() => { closePopup() }} className="btn-close" aria-label="Close"></button>
        </div>
        <ModalBody>
          <div className="modal-foot mt-2 text-center">
            <button onClick={() => { ShowPopuppublish() }} className="btn btn-primary min-sm-w">Publish Coupon</button>
            <button onClick={() => { showBookappoinmentfun() }} className="btn btn-secondry min-sm-w ms-2">Add Appointment</button>
          </div>
        </ModalBody>

      </Modal>

      <Modal className="modal fade common-modal delete-box-style" show={showPopuanpublish} backdrop="static">
        <div className="modal-head text-center mb-2">
          <h1 className="modal-title " id="exampleModalLabel">Add Coupon to Slot</h1>
          <button type="button" onClick={() => { closePopup() }} className="btn-close" aria-label="Close"></button>
        </div>
        <ModalBody>
        {Array.isArray(categoryList) && categoryList.length > 0 ? (
          <h4 className="model-sm-title">Select Coupon to add</h4>
        ) :''}
          <ul className="coustom-check-list">
            {categoryList.map((item, index) =>
              <li key={item.id}>
                <div className="form-check coustom-check" onClick={() => setSelectedCouponId(item.id)}>
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id={`flexRadioDefault${index}`}/>
                  <label className="form-check-label" htmlFor={`flexRadioDefault${index}`}>
                    <div className="box-shadow coupan-box Popular-coupan-box p-3 d-flex" onClick={() => setSelectedCouponId(item.id)}>
                      <figure><img src={MEDIA_BASE_URL + item.couponImage} alt="coupon" /></figure>
                      <figcaption>
                        <div className="coupan-content-head d-flex align-items-center justify-content-between pb-1">
                          <h4 className="pb-0">{item.title}</h4>
                          <b className="price-text">${item.price}</b>
                        </div>
                        <span className="discount-text mb-0">Discount of {item.disPercentage}%</span>
                        <p className="discount-text mb-0">{TextLimitShow(item.description, 12)}</p>
                      </figcaption>
                    </div>
                  </label>
                </div>
              </li>
            )}
          </ul>
        </ModalBody>
        {console.log(categoryList)}
        {Array.isArray(categoryList) && categoryList.length > 0 ? (
        <div className="modal-foot mt-2 text-center">
          <button className="btn btn-primary min-sm-w calenderedit-coupan-temp" onClick={handleEditCoupon}>
            Edit Coupon Template 
          </button>
          <button className="btn btn-secondry min-sm-w ms-2" onClick={handlePublishCoupon}>
            Publish Coupon
          </button>
        </div>
        ) : 
        <div className="modal-foot mt-2 text-center">
          <p>No Coupons published yet. Please start with publishing your first Coupon by going to Coupon Templates.</p><br/>
          <button className="btn btn-secondry min-sm-w calenderedit-coupan-temp" onClick={handlePublicCouponTemplate}>Coupon Template</button>
        </div>}
      </Modal>

      <Modal className="modal fade common-modal delete-box-style" show={showBookappoinment} backdrop="static">
        <div className="modal-head text-center mb-2">
          <h1 className="modal-title " id="exampleModalLabel">Add Appointment</h1>
          <button type="button" onClick={() => { closePopup() }} className="btn-close" aria-label="Close"></button>
        </div>
        <ModalBody>
          <h1 className="model-sm-title model-custom-title">Coming Soon</h1>
        </ModalBody >
      </Modal>
    </div>
  );
}