import React from 'react'
import MyProfile from './MyProfile'
import ChangePassword from './ChangePassword'
import { Link, useParams } from 'react-router-dom';

export default function MyAccount() {
    const {tab} = useParams();    
    return (
        <div className="my-account-sec section-space">
            <div className="container">
                <div className="coupan-sec-top-head mb-4">
                    <div className="page-title ">
                        <h1>My Account</h1>
                    </div>
                </div>
                <div className="my-account-info-main mt-5">
                    <div className="my-account-main d-flex">
                        <div className="my-account-left">
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <Link to="/merchant/my-account/profile/detail" className={"nav-link " + (tab==="profile" ? "active" : "")} ><i className="ti ti-user"></i> My  Profile</Link>
                                <Link to="/merchant/my-account/change-password" className={"nav-link responsive-nav-acc " + (tab==="change-password" ? "active" : "")}><i className="ri-lock-line"></i> Change  Password</Link>
                            </div>
                        </div>
                        <div className="my-account-right ms-5">
                            <div className="tab-content" id="nav-tabContent">
                                {tab==="profile" &&  <MyProfile />}
                                {tab==="change-password" && <ChangePassword />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
