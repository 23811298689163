import React, { useEffect, useState } from 'react'
import { getProfile } from '../../Services/MyAccountServices'
import { Link } from 'react-router-dom'
import logo from '../../Assets/images/logo.png'

export default function MyProfileDetail() {
    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(false); // State for loader
    const [startTime, setStartTime] = useState(null); // Start time of update process

    useEffect(() => {
    setStartTime(new Date()); // Record start time
    setLoading(true); // Show loader before making API call
    getProfile().then((res)=>{
        if(res.status==200){
            setTimeout(() => {
                setLoading(false); // Hide loader after API call completes
                setStartTime(null); // Reset start time
                const respData = res.data.data;
                const categories = respData.categoryIds.map(item=>item.name).join(", ");
                setUser({
                    ...respData,
                    categories
                })
            },500);
        }
      }).catch((err)=>{console.log(err)})    
    }, [])
    
    return (
        <div className="tab-pane fade show active">
            {loading && (
                <div className='preload'>
                    <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
                </div>
            )}
            <div className="box-shadow">
                <div className="my-account-right-head d-flex align-items-center justify-content-between mb-4">
                    <div className="section-title ">
                        <h2>My Profile</h2>
                    </div>
                    <ul className="action-info d-flex align-items-center">
                        <li className="edit-action ping-bg-edit">
                            <Link to="/merchant/my-account/profile/update">
                                <i className="ri-edit-line"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
                <ul className="my-profile-list">
                    <li><b>Business Name</b><span>{user?.businessName ? user?.businessName : "-"}</span></li>
                    <li><b>Representative Name</b><span>{user?.representName ? user?.representName : "-"}</span></li>
                    <li><b>Email</b><span>{user?.email ? user?.email : "-"}</span></li>
                    <li><b>Phone Number</b><span>{user?.phoneNumber ? user?.phoneNumber : "-"}</span></li>
                    <li><b>Category</b><span>{user?.categories ? user?.categories : "-"}</span></li>                    
                    <li className="single-box-text">
                        <b>About Us</b><br />
                        <p>
                            {user?.aboutUs ? user?.aboutUs : "-"}
                        </p>
                    </li>
                    <li><b>Facebook Link</b><span>{user?.fbLink ? user?.fbLink : "-"}</span></li>
                    <li><b>Instagram Link</b><span>{user?.instaLink ? user?.instaLink : "-"}</span></li>
                    <li><b>Twitter Link</b><span>{user?.twitterLink ? user?.twitterLink : "-"}</span></li>                    
                    <li><b>Website</b><span>{user?.website ? user?.website : "-"}</span></li>                    
                </ul>
            </div>
        </div>
    )
}
