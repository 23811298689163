import React from 'react'
import logo from '../../Assets/images/logo.png'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer>
      <div className="footer-box">
        <div className="container">
          <div className="row">
            <div className="col-md-4 footer-content">
              <div className="footer-top-left">
                <figure className="mb-4"><Link to="/merchant/dashboard"><img src={logo} alt='logo' /></Link></figure>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud  </p>
              </div>
            </div>
            <div className="col-md-4 ms-auto footer-content ps-5">
              <h3>Navigation</h3>
              <ul className="footer-link">
                <li><Link to="/merchant/dashboard">Dashboard</Link></li>
                <li><Link to="/merchant/my-coupon/published-coupon/list">My Coupons</Link></li>
                <li><Link to="/merchant/business-config/detail">Business Configuration</Link></li>
                <li><Link to="/merchant/settings">Settings</Link></li>
                <li><Link to="/merchant/support">Support</Link></li>
              </ul>
            </div>
            <div className="col-md-3 footer-content">
              <h3>Social Media</h3>
              <ul className="Social-link d-flex">
                <li>
                  <Link to="#" className='mb-2'>
                    <i className="ri-twitter-fill"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="ri-facebook-fill"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="ri-google-fill"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
