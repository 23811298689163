import React, { useEffect, useState } from 'react';
import { getNotificationSettings, updateNotificationSettings } from '../../Services/DashBoardServices';
import { tostE, tostS } from '../../Utils/Toast';

export default function SettingScreen() {
    const [allNotifications, setAllNotifications] = useState(false);
    const [couponPurchase, setCouponPurchase] = useState(false);
    const [couponRedeem, setCouponRedeem] = useState(false);

    useEffect(() => {
        // Fetch default notification settings when component mounts
        const fetchDefaultNotificationSettings = () => {
            // Make API request to get default notification settings
            getNotificationSettings()
                .then((res) => {
                    setAllNotifications(res.data.data.allNotifications);
                    setCouponPurchase(res.data.data.couponPurchase);
                    setCouponRedeem(res.data.data.couponRedeem);
                })
                .catch((err) => {
                    console.log(err);
                    if (err?.response?.data) {
                        tostE(err?.response?.data?.message);
                    }
                });
        };
        fetchDefaultNotificationSettings();
    }, []);

    const handleAllNotificationsToggle = () => {
        const newValue = !allNotifications;
        setAllNotifications(newValue);
        setCouponPurchase(newValue);
        setCouponRedeem(newValue);
        const payload = {
            allNotifications: newValue,
            couponPurchase: newValue,
            couponRedeem: newValue
        };
        callUpdateNotificationSettings(payload);
    };

    const handleCouponPurchaseToggle = () => {
        const newValue = !couponPurchase;
        const newAllNotifications = newValue && couponRedeem ? newValue : false;
        setCouponPurchase(newValue);
        setAllNotifications(newAllNotifications);
        const payload = {
            allNotifications: newAllNotifications,
            couponPurchase: newValue,
            couponRedeem
        };
        callUpdateNotificationSettings(payload);
    };

    const handleCouponRedeemToggle = () => {
        const newValue = !couponRedeem;
        const newAllNotifications = couponPurchase && newValue ? newValue : false;
        setCouponRedeem(newValue);
        setAllNotifications(newAllNotifications);
        const payload = {
            allNotifications: newAllNotifications,
            couponPurchase,
            couponRedeem: newValue
        };
        callUpdateNotificationSettings(payload);
    };

    const callUpdateNotificationSettings = (payload) => {
        updateNotificationSettings(payload)
            .then((res) => {
                if (res.status === 200) {
                    tostS(res.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err?.response?.data) {
                    tostE(err?.response?.data?.message);
                }
            });
    };

    return (
        <div className="setting-sec section-space">
            <div className="container">
                <div className="coupan-sec-top-head mb-4">
                    <div className="page-title">
                        <h1>Settings</h1>
                    </div>
                </div>
                <div className="setting-info-main mt-5">
                    <div className="row">
                        <div className="col-md-8 add-new-coupon-box m-auto">
                            <div className="box-shadow ">
                                <div className="setting-info-head d-flex align-items-center justify-content-between">
                                    <h4>All Notifications</h4>
                                    <div className="switch-toggle-xs">
                                        <label className="switch">
                                            <input type="checkbox" checked={allNotifications} onChange={handleAllNotificationsToggle} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <hr />
                                <ul className="Notifications-list">
                                    <li>
                                        <b>Coupon Purchase</b>
                                        <div className="switch-toggle-xs">
                                            <label className="switch">
                                                <input type="checkbox" checked={couponPurchase} onChange={handleCouponPurchaseToggle} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <b>Coupon Redeem</b>
                                        <div className="switch-toggle-xs">
                                            <label className="switch">
                                                <input type="checkbox" checked={couponRedeem} onChange={handleCouponRedeemToggle} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
