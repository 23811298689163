import React, { useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Datetime from 'react-datetime';
import * as Yup from 'yup';
import 'react-datetime/css/react-datetime.css';
import { getCouponDetails, publishCoupon } from '../../../Services/CoupanServices';
import { MEDIA_BASE_URL } from '../../../Utils/Constants';
import { tostE, tostS } from '../../../Utils/Toast';
import logo from '../../../Assets/images/logo.png';
import dayjs from 'dayjs';
import { DemoContainer} from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import moment from 'moment';

export default function PublishCoupon() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [price, setPrice] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState('');
  const [disPercentage, setDisPercentage] = useState('');
  const [coupanimg, setCoupanImg] = useState('');
  const [title, setCoupanTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [showDurationOptions, setShowDurationOptions] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState('daily');
  const [numberofslotss, setNumberofslotss] = useState('1');
  const [missingDaysError, setMissingDaysError] = useState('');
  const [coupandetails, setCoupanDetails] = useState('');
  

  const location = useLocation();
  const { state } = location; 

 
  if (state && state.dateAndTime) {
    const { dateAndTime } = state; 
    console.log(dateAndTime.dateTime)
  }


  const handleNumberOfSlotsChange = (event) => {
    const { value } = event.target;
    setNumberofslotss(value);
    formik.setFieldValue('numberOfSlots', value);
  };
  

  useEffect(() => {


    const fetchCouponDetails = async () => {
      setStartTime(new Date()); // Record start time
      setLoading(true); // Show loader before making API call
      try {
        const response = await getCouponDetails(id);
        if (response?.status === 200) {
         
            setCoupanImg(response?.data?.data?.couponImage);
            setDisPercentage(response?.data?.data?.disPercentage);
            setPrice(response?.data?.data?.price);
            setDiscountedPrice(response?.data?.data?.discountedPrice);
            setCoupanTitle(response?.data?.data?.title);
            setCoupanDetails(response?.data?.data);
  

        } else {
          console.error('Failed to fetch coupon details:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching coupon details:', error);
      }
      finally{
        setTimeout(() => {
        setLoading(false);
      }, 1000);
      }
      
    };
//    setLoading(false);
    fetchCouponDetails();
  }, [id]);


const validationSchema = Yup.object().shape({
  startDate: Yup.date()
    .required('Start Date is required')
    .test('is-future-date', 'Start Date must be today or a future date', function(startDate) {
      // Get the current date without time
      const currentDate = new Date();
      const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
      // Compare selected start date with today's date
      return startDate && startDate >= today;
    }),
    
  endDate: Yup.date()
    .required('End Date is required')
    .min(Yup.ref('startDate'), 'End Date must be after or equal to Start Date'),
  slotStartTime: Yup.string()
    .required('Slot Start Time is required') 
    .test('is-after-or-equal-to-current-time', 'Please enter valid time.', 
    function(value) {
      // Parse the slot start time to compare with the current time
      const currentTime = dayjs().add(30, 'minutes').format('HH');
      const slotStartTime = dayjs(value).format('HH');
      return areBothDatesToday ? slotStartTime >= currentTime :isStartDateToday ? selectedDuration === 'daily' ? slotStartTime >= currentTime : slotStartTime :slotStartTime;
      // return selectedDuration === 'daily' ? slotStartTime >= currentTime :  slotStartTime;
    }),
  numberOfSlots: Yup.number()
    .required('Number of slots is required')
    .min(1, 'Number of slots should be between 1 to 100')
    .max(100, 'Number of slots should be between 1 to 100')
    .integer('Number of slots must be an integer')
    .typeError('Please enter a valid number'),
  selectedDays: Yup.object().when('selectedDuration', {
    is: 'daysOfWeek',
    then: Yup.object().test('has-selected-days', 'Some selected days are missing between Start Date and End Date', function(selectedDays) {
      const { startDate, endDate } = this.parent;
      const daysOfWeek = Object.keys(selectedDays).filter(day => selectedDays[day]);
      const start = new Date(startDate);
      const end = new Date(endDate);
      let missingDays = [];
      while (start <= end) {
        if (daysOfWeek.indexOf(start.toLocaleDateString('en-US', { weekday: 'lowercase' })) === -1) {
          missingDays.push(start.toLocaleDateString('en-US', { weekday: 'lowercase' }));
        }
        start.setDate(start.getDate() + 1);
      }
      return missingDays.length === 0;
    })
  })
});


  const formik = useFormik({
    initialValues: {
        startDate:state && state?.dateAndTime?.date,
        endDate: '',
        numberOfSlots:numberofslotss,
        slotStartTime: state && state?.dateAndTime.time? state && state?.dateAndTime.time: dayjs().add(30, 'minutes').format('HH:mm')??'',
        selectedDays: {
          sunday: false,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
      },
    },
    validationSchema: validationSchema,
    
    onSubmit: async (values, { resetForm }) => {


       const { startDate, endDate, numberOfSlots, slotStartTime, selectedDays } = values;
      const currentTime = dayjs().format('HH:mm');
    
      const isValidSlotTime = areBothDatesToday ? slotStartTime >= currentTime : 
      isStartDateToday ? selectedDuration === 'daily' ? slotStartTime >= currentTime : slotStartTime : slotStartTime;

      if (!isValidSlotTime) {
          const errorMessage = "Invalid slot start time."; // Define your error message
          setFieldError('slotStartTime', errorMessage); // Set the error message for the slotStartTime field
          return; // Exit early
      }
  
      // Store the final value (if needed)
      const finalValue = isValidSlotTime ? slotStartTime : null; // Adjust this as needed
      let capitalizedMissingDays
      let dailyShow;
      let selectedDayNames;
      if(new Date(values.endDate) > new Date(values.startDate) ){
        if (selectedDuration === 'daily') {
          dailyShow = true;
          selectedDayNames = []; // Set to null when 'Daily' is selected
        } else if (selectedDuration === 'daysOfWeek') {
          dailyShow = false;
          selectedDayNames = Object.keys(selectedDays).filter(day => selectedDays[day]);
          capitalizedMissingDays = selectedDayNames.map(day => day.charAt(0).toUpperCase() + day.slice(1));

        }else{
          dailyShow = false;
        }
      }
      else{
        dailyShow = false;
        selectedDayNames = [];
      }
// Get the current date and time in UTC
const currentDateTime = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

// Assuming values.startDate and values.endDate are in local time
const formattedStartDate = dayjs(values.startDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
const formattedEndDate = dayjs(values.endDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

const combinedStartDateTime = `${formattedStartDate.substring(0, 10)}${currentDateTime.substring(10)}`;
const combinedEndDateTime = `${formattedEndDate.substring(0, 10)}${currentDateTime.substring(10)}`;

console.log(" (UTC)", combinedStartDateTime);
console.log(" (UTC)", combinedEndDateTime);


      setStartTime(new Date()); // Record start time
      setLoading(true); // Show loader before making API call
  
      // Prepare the data object to be sent to the API
      const payload = {
        id:id,
        startDateTime: combinedStartDateTime,
        endDateTime: combinedEndDateTime,
        numberOfSlots:numberofslotss,
        price,
        disPercentage,
        discountedPrice,
        slotStartTime:finalValue,
        dailyShow,
        weeklyShow: capitalizedMissingDays,
        title,
        description:coupandetails?.description,
        duration:coupandetails?.duration,
        durationText:coupandetails?.durationText,
        categoryId:coupandetails?.categoryId,
        couponImage:coupandetails?.couponImage,
        galleryImages: coupandetails?.galleryImages,
      };    

    //  console.log(payload)
      publishCoupon(payload).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            setLoading(false); // Hide loader after API call completes
           // setStartTime(null); // Reset start time
            navigate(`/merchant/my-coupon/published-coupon/list`);
            tostS(res?.data?.message);
          },500);
        }
      }).catch((err) => {
      //  console.log(err);
        if (err?.response?.data) {
          setLoading(false); // Hide loader after API call completes
          setStartTime(null); // Reset start time
          tostE(err?.response?.data?.message);
        }
      }).finally(() => {

        setTimeout(() => {
          setLoading(false); // Hide loader after API call completes
          //setStartTime(null); // Reset start time
        }, 500);
      });
    }    
  });
  

  const { errors, values, touched, handleSubmit, handleChange, setFieldValue,setFieldError } = formik;
  const currentDateTime = dayjs(); // Get the current date and time
  const maxDateTime = currentDateTime.endOf('day'); // Calculate the end of the current day
  const currentTimeIsPM = currentDateTime.hour() >= 12; // Check if current time is PM

  useEffect(() => {
    if (values?.startDate === values?.endDate) {
      setShowDurationOptions(false);
    } else {
      setShowDurationOptions(true);
    }
  }, [values?.startDate, values?.endDate]);

  // Function to calculate the days between two dates
  function getDaysInRange(startDate, endDate) {
    const days = [];
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      days.push(currentDate.getDay()); // Push the day of the week (0-6)
      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }
    return days;
  }

  // Function to get the index of the day
  function getDayIndex(day) {
    const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    return daysOfWeek.indexOf(day.toLowerCase());
  }

  // Function to check if the selected day is missing in the range
  function checkMissingDay(selectedDay, startDate, endDate) {
    const daysInRange = getDaysInRange(startDate, endDate);
    return !daysInRange.includes(selectedDay);
  }

  const handleSelectedDaysChange = (day) => {
    return (event) => {
      const { checked } = event.target;
      const newSelectedDays = { ...values.selectedDays, [day]: checked };
      setFieldValue('selectedDays', newSelectedDays);
      const missingDays = Object.keys(newSelectedDays).filter(day => newSelectedDays[day] && checkMissingDay(getDayIndex(day), values.startDate, values.endDate));
      
      // Capitalize the first letter of each word in missingDays
      const capitalizedMissingDays = missingDays.map(day => day.charAt(0).toUpperCase() + day.slice(1));
      
      if (capitalizedMissingDays.length > 0) {
        setMissingDaysError(`There are no ${capitalizedMissingDays.join(', ')} between the selected Start Date and End Date. Please adjust your selection to include at least one ${capitalizedMissingDays.join(', ')}.`);
      } else {
        setMissingDaysError('');
      }
    };
  };

  useEffect(() => {
    if (values?.startDate === values?.endDate) {
      setShowDurationOptions(false);
    } else {
      setShowDurationOptions(true);
      // Reset missing days error when end date changes
      setMissingDaysError('');
    }
  }, [values?.startDate, values?.endDate]);


  
  const today = new Date();
  const startDate = new Date(values.startDate);
  const endDate = new Date(values.endDate);
  const isStartDateToday = startDate.toDateString() === today.toDateString();
  const areBothDatesToday = startDate.toDateString() === today.toDateString() && endDate.toDateString() === today.toDateString();
//  const result = areBothDatesToday ? "yes" : (startDate >= today ? "no" : 'ss');


  return (

    
    <div className="publish-coupon-sec section-space">      
      {loading && (
        <div className='preload'>
          <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
        </div>
      )}
      <div className="container">
        <div className="coupan-sec-top-head mb-4">
          <div className="page-title">
            <h1>Publish Coupon Template</h1>
          </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/merchant/my-coupon/published-coupon/list">My Coupons</Link>
              </li>
              <li className="breadcrumb-item"><Link to={`/merchant/my-coupon/coupon-template/list`}>Coupon Templates</Link></li>
              <li className="breadcrumb-item">
                <Link to={`/merchant/my-coupon/coupon-template/detail/${id}`}>Coupon Template Detail</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Publish Coupon Template
              </li>
            </ol>
          </nav>
        </div>
          
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <div className="publish-coupon-info-main mt-5">
              <div className="row">
                <div className="col-md-8 publish-coupon-box m-auto">
                  <div className="box-shadow">
                    <div className="row">
                      <div className="col-md-12 mb-6 public-box-title-section">
                        <div className="col-md-2">
                          <img src={MEDIA_BASE_URL+ coupanimg} alt="img" />
                        </div>
                        <div className="col-md-9 mb-4">
                          <h4>{title}</h4>
                          <p>
                            List Price: <b>${price}</b> Discounted Price: <b>${discountedPrice}</b>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Start Date</label>
                          <Datetime
                            value={values?.startDate}
                            onChange={(date) => setFieldValue('startDate', date)?._d}
                            inputProps={{ placeholder: 'Select Start Date', readOnly: true }} // Add readOnly attribute
                            timeFormat={false}
                            dateFormat="MM/DD/YYYY"
                            isValidDate={(currentDate) => currentDate.isSameOrAfter(new Date(), 'day')}
                          />
                        {errors.startDate && touched.startDate && (
                          <span className="form-error">{errors.startDate}</span>
                        )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">End Date</label>
                        <Datetime
                          value={values.endDate}
                          onChange={(date) => setFieldValue('endDate', date?._d)}
                          inputProps={{ placeholder: 'Select End Date', readOnly: true }} // Add readOnly attribute
                          dateFormat="MM/DD/YYYY"
                          timeFormat={false}
                          name="endDate"
                          isValidDate={(currentDate) => currentDate.isSameOrAfter(new Date(), 'day')}
                        />
                        {errors.endDate && touched.endDate && (
                          <span className="form-error">{errors.endDate}</span>
                        )}
                      </div>
                      {new Date(values.endDate) > new Date(values.startDate) && (
                        <div className="col-md-12 mb-3">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="selectedDuration"
                              id="dailyRadio"
                              value="daily"
                              checked={selectedDuration === 'daily'}
                              onChange={( () => setSelectedDuration('daily') )}
                            />
                            <label className="form-check-label" htmlFor="dailyRadio">
                              Daily
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="selectedDuration"
                              id="daysOfWeekRadio"
                              value="daysOfWeek"
                              checked={selectedDuration === 'daysOfWeek'}
                              onChange={() => setSelectedDuration('daysOfWeek')}
                            />
                            <label className="form-check-label" htmlFor="daysOfWeekRadio">
                              Days of Week
                            </label>
                          </div>
                        </div>
                      )}      
                      {new Date(values.endDate) > new Date(values.startDate) && (
                        selectedDuration === 'daysOfWeek' && (
                            <div className="col-md-12 mb-3">
                                <div className="form-check">
                                    {Object.keys(values.selectedDays).map(day => (
                                        <div key={day} className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={day}
                                                name={`selectedDays.${day}`}
                                                checked={values.selectedDays[day]}
                                                onChange={handleSelectedDaysChange(day)}
                                            />
                                            <label className="form-check-label" htmlFor={day}>
                                                {day.charAt(0).toUpperCase() + day.slice(1)}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                {missingDaysError && <span className="form-error">{missingDaysError}</span>}
                                {selectedDuration === 'daysOfWeek' && Object.values(values.selectedDays).every(day => !day) && (
                                  <div className="col-md-12 mb-3">
                                    <span className="form-error">Please select at least one day of the week.</span>
                                  </div>
                                )}
                            </div>
                        )
                      )}

                      <div className="col-md-6 mb-3">
                        <label className="form-label">Slot Start Time</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['MobileTimePicker']} >
                              <MobileTimePicker 
                                  // minTime={selectedDuration === 'daily' ? dayjs(`2022-04-17T${values.slotStartTime}`) : null}                            
                                  maxTime={selectedDuration === 'daily' ? null : maxDateTime}                
                                  className='mobilepicker-custom'
                                  defaultValue={state && state?.dateAndTime?.dateTime ? dayjs(state?.dateAndTime?.dateTime) : dayjs(`2022-04-17T${values.slotStartTime}`)}                          
                                  onChange={(time) => {
                                      if (areBothDatesToday) {
                                          if (selectedDuration === 'daily' && time.hour()<new Date().getHours()) {
                                            tostE("Please enter valid time.")
                                            setFieldValue('slotStartTime', time);
                                          }else{
                                            const formattedTime = time.format("HH:mm");
                                            setFieldValue('slotStartTime', formattedTime);
                                          }
                                      }else {
                                          if (isStartDateToday) {
                                              if (selectedDuration === 'daily' && time.hour()<new Date().getHours()) {
                                                tostE("Please enter valid time")
                                                setFieldValue('slotStartTime', time);
                                              }else{
                                                const formattedTime = time.format("HH:mm");
                                                setFieldValue('slotStartTime', formattedTime);
                                              }
                                          } else {
                                            const formattedTime = time.format("HH:mm");
                                            setFieldValue('slotStartTime', formattedTime);
                                          }
                                      }
                                   }
                                  }
                                  disablePast={areBothDatesToday ? true :isStartDateToday ? selectedDuration === 'daily' ? true : false :false}
                                  inputProps={{
                                    placeholder: 'Select Start Time',
                                    readOnly: true,
                              }}
                               />
                          </DemoContainer>
                        </LocalizationProvider>
                        {errors.slotStartTime && touched.slotStartTime && (
                          <span className="form-error">{errors.slotStartTime}</span>
                        )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Number of Slots</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter between 1 to 100"
                            name="numberOfSlots"
                            onChange={handleNumberOfSlotsChange}
                            value={numberofslotss}
                          />
                        {errors.numberOfSlots && touched.numberOfSlots ? <span className="form-error">{errors.numberOfSlots}</span> : null}
                      </div>
                      <div className="col-md-12 text-center mt-4 btn-group-box">
                        <button type='submit' className="btn btn-secondry max-w" disabled={loading}>Publish</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
}
