import AxiosInterceptor from "../Utils/AxiosInterceptor";

const PREFIX = "access/";

export const getUserTerms = async () => {
    return await AxiosInterceptor().get(PREFIX + `getUserTerms`);
};

export const getTerms = async () => {
    return await AxiosInterceptor().get(PREFIX + `getTerms`);
};

export const getPrivacyPolicy = async () => {
    return await AxiosInterceptor().get(PREFIX + `getPrivacyPolicy`);
};

export const getUserPrivacyPolicy = async () => {
    return await AxiosInterceptor().get(PREFIX + `getUserPrivacyPolicy`);
};


export const getRegisterAgreement = async () => {
    return await AxiosInterceptor().get(PREFIX + `getRegisterAgreement`);
};

