import React from 'react'
import businessConfigImg from '../../../Assets/images/busi-conf-img.png'
import { useParams } from 'react-router-dom'
import BusinessConfigDetail from './BusinessConfigDetail'
import UpdateBusinessConfig from './UpdateBusinessConfig'
export default function BusinessConfig() {
   const { page } = useParams()
   return (
      <>
      {page==="detail" && <BusinessConfigDetail />}
      {page==="update" && <UpdateBusinessConfig />}
      </>
   )
}
