const chartLabels = ['January', 'February', 'March', 'April', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const chartConfig = {
    plotOptions: { bar: { columnWidth: '16%' } },
    // fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: 'month' },
    tooltip: {
        shared: true,
        intersect: false,
        y: {
            formatter: (y) => {
                if (typeof y !== 'undefined') {
                    return `${y.toFixed(0)} `;
                }
                return y;
            },
        },
        fillSeriesColor:true
    },
    // Chart
    chart: {
        toolbar: { show: false },
        zoom: { enabled: false },
        // animations: { enabled: true },
        foreColor: "#C2C0C0",
        // fontFamily: theme.typography.fontFamily,
    },
    // States
    states: {
        hover: {
            filter: {
                type: 'lighten',
                value: 0.04,
            },
        },
        active: {
            filter: {
                type: 'darken',
                value: 0.88,
            },
        },
    },
    // Fill
    fill: {
        opacity: 1,
        colors:["#fa5856"],
        gradient: {
            type: 'vertical',
            shadeIntensity: 0,
            opacityFrom: 0.4,
            opacityTo: 0,
            stops: [0, 100],
        },
    },
    // Stroke
    stroke: {
        colors:["#fc2e2b"],
        width: 2,
        curve: 'smooth',
        lineCap: 'round',
    },

    // Datalabels
    dataLabels: { enabled: false },
    // Grid
    grid: {
        strokeDashArray: 0,
        borderColor: "#C2C0C0",
        xaxis: {
            lines: {
                show: false,
            },
        },
    },
    // Xaxis
    xaxis: {
        axisBorder: { show: false },
        axisTicks: { show: false },
    },

    // Markers
    markers: {
        size: 0,
        strokeColors: "#fff",
        colors:["#fa5856"]
    },

    // Tooltip
    tooltip: {
        x: {
            show: false,
        },
    },
    // Responsive
    responsive: [
        {
            // sm
            breakpoint: 600,
            options: {
                plotOptions: { bar: { columnWidth: '40%' } },
            },
        },
        {
            // md
            breakpoint: 900,
            options: {
                plotOptions: { bar: { columnWidth: '32%' } },
            },
        },
    ],
    // Legend
    legend: {
        show: true,
        fontSize: 13,
        position: 'top',
        horizontalAlign: 'right',
        markers: {
            radius: 12,
        },
        fontWeight: 500,
        itemMargin: { horizontal: 0 },
        labels: {
            colors: "#FFF",
        },
    },
};