import AxiosInterceptor from "../Utils/AxiosInterceptor";

const PREFIX = "access/";

export const getNotifications = async () => {
    return await AxiosInterceptor().get(PREFIX + `getNotifications`);
};


export const updateNotificationSettings = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `updateNotificationSettings` ,payload);
};

export const getNotificationSettings = async (payload) => {
    return await AxiosInterceptor().get(PREFIX + `getNotificationSettings` ,payload);
};
