import React, { useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { resetPassword } from '../../Services/AuthServices';
import { tostE, tostS } from '../../Utils/Toast';
import { cleanAllData } from '../../Actions/ActionCreator';
import { PASSWORD_COMPLEX_REGEX } from '../../Utils/Constants';
import logo from './../../Assets/images/logo.png'

export default function ResetPassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user && state.user.data ? state.user.data : null);
    const [loading, setLoading] = useState(false); // State for loader
    const [startTime, setStartTime] = useState(null); // Start time of update process

    const resetPassValidation = Yup.object().shape({
        newPassword: Yup.string().required('Please Enter new password').min(8, "Password must be 8 characters long alphanumeric containing at least 1 uppercase letter and 1 special character.")
        .matches(PASSWORD_COMPLEX_REGEX, "Password must be 8 characters long alphanumeric containing at least 1 uppercase letter and 1 special character."),
        confirmPassword: Yup.string().required('Please confirm Password').oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            newPassword: "",
            confirmPassword: ""
        },
        validationSchema: resetPassValidation,
        onSubmit: async (values) => {
            setStartTime(new Date()); // Record start time
            setLoading(true); // Show loader before making API call
            const payload = {
                email: currentUser?.email,
                newPassword: values.newPassword,
                confirmPassword: values.confirmPassword
            }
            resetPassword(payload).then((res) => {
                
                if (res.status === 200) {   
                    setTimeout(() => {
                        setLoading(false); // Hide loader after API call completes
                       // setStartTime(null); // Reset start time
                       tostS(res?.data?.message)                 
                       dispatch(cleanAllData(res.data.data))                    
                       navigate("/auth/login", {replace:true})
                    }, 2000);
                }
            }).catch((err) => {
                console.log(err);
                if(err?.response?.data){
                    tostE(err?.response?.data?.message);
                }
            }).finally((err1) => {
                setTimeout(() => {
                    setLoading(false); // Hide loader after API call completes
                    setStartTime(null); // Reset start time
                    tostE(err1?.response?.data?.message);
                }, 500);
            });   
        }
    })

    const { errors, values, touched, handleSubmit, handleChange, handleBlur } = formik;
   
    return (
        <>
            {loading && (
                <div className='preload'>
                    <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
                </div>
            )}        
            <div className="form-head mb-4 text-center">
                <h4>Reset Password</h4>
                <p>Enter the new password</p>
            </div>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <div className="form-body">
                        <div className="form-group mb-2">
                            <label className="form-label">New Password</label>
                            <div className='password-field'>
                                <input 
                                    name="newPassword"
                                    type="password" 
                                    placeholder="Enter new password" 
                                    className={"form-control" + (errors.newPassword && touched.newPassword ? " is-invalid" : "")} 
                                    value={values.newPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="New Password"
                                    autoComplete='off'
                                />      
                                {errors.newPassword && touched.newPassword ? <span className="form-error">{errors.newPassword}</span> : null}                          
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label className="form-label">Confirm New Password</label>
                            <input 
                                name="confirmPassword"
                                type="password" 
                                placeholder="Confirm password" 
                                className={"form-control" + (errors.confirmPassword && touched.confirmPassword ? " is-invalid" : "")} 
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                title="New Password"
                                autoComplete='off'
                            />
                            {errors.confirmPassword && touched.confirmPassword ? <span className="form-error">{errors.confirmPassword}</span> : null}                          
                        </div>
                        <div className="form-btn-box mt-4">
                            <button type='submit' className="btn btn-secondry w-100">Save </button>
                        </div>

                    </div>
                </Form>
            </FormikProvider>
            <div className="form-bottom form-bottom-text">
                <Link
                    to={"/auth/login"}
                    className="form-link"
                >
                    Back to Login
                </Link>
            </div>
        </>
    )
}
