import React from 'react'
import { useSelector } from 'react-redux'
import DashBoardRoutes from './DashBoardRoutes'
import AuthRoutes from './AuthRoutes'

export default function Routes() {
    const auth = useSelector((state)=> state.user &&  state.user.token && 
    state.user.token !== null && state.user.token !==  undefined 
    && state.user.token !== "" ? state.user.token : null);

    return (
        auth ? 
        <DashBoardRoutes /> 
        : <AuthRoutes />

    )
}
