import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import logo from '../Assets/images/logo.png'

export default function AuthLayout() {
  const location = useLocation();
  const isLoginPage = location.pathname === '/auth/login';
  return (
<>
    {isLoginPage ? 
    <div className="wraper-inner">
       <span className='login-support-button'><Link to="/common/support">Support</Link></span>
      <section className="form-page">
        <div className="form-inner form-space">
          <div className="container">
            <div className='login-logo-withoutnav'> <img src={logo} alt="Insta-coupon Logo" /></div>
            <div className="login-form common-form pb-0 login-form-box-imgremove">
              <Outlet />            
            </div>
          </div>
        </div>
      </section>
    </div>
    :     <div className="wraper-inner">
    <section className="form-page">
      <div className="top-head">
        <div className="container">
            <figure>
              <img src={logo} alt="Insta-coupon Logo" className='login-head-logo-pg' />
            </figure>
        </div>
      </div>
      <div className="form-inner form-space">
        <div className="container">
          <div className="login-form common-form pb-0">
            <Outlet />            
          </div>
        </div>
      </div>
    </section>
  </div>
  }
  </>
  )
}
