import React from 'react'
import { useParams } from 'react-router-dom';
import MyProfileDetail from './MyProfileDetail';
import MyProfileUpdate from './MyProfileUpdate';

export default function MyProfile() {
    const {tab, page} = useParams();
    
    return (
        <>
        {(tab==="profile" && page === "detail") && <MyProfileDetail />}
        {(tab==="profile" && page === "update") && <MyProfileUpdate />}
        </>
    )
}
