import AxiosInterceptor from "../Utils/AxiosInterceptor";

const PREFIX = "coupon/";


export const getCouponTemplateList = async () => {
    return await AxiosInterceptor().get(PREFIX + `getCouponTemplateList`);
};


export const getCalendarDetails = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `getCalendarDetails`,payload);
};

export const redeemCoupon = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `redeemCoupon`,payload);
};
