import React, { useState } from 'react'
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { REACT_APP_GOOGLE_API_KEY } from '../../Utils/Constants';
const GoogleAutocompleteComp = ({formik}) => {
    const [autocomplete, setAutocomplete] = useState(null);

    const onLoad = (autocomplete) => {
        setAutocomplete(autocomplete);
    }

    const presentInArray = (data, checkKeys) => {
        let present = false
        checkKeys?.map((key) => {
            if (data?.includes(key)) {
                present = true
            }
        })
        return present

    }


    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const places = autocomplete.getPlace()
            console.log("places", places)
            let fData = {
                state: "",
                city: "",
                address: "",
                zipCode: "",
                lat:"",
                lng:""
            };


            const availableAddComponents = [];
            places?.address_components?.map((x) => {
                if (presentInArray(x?.types, ["premise", "street_number", "route", "sublocality_level_3"])) {
                    fData["address"] = fData.address ? `${fData.address}, ${x.long_name}` : `${x.long_name}`;
                }

                if (
                    x.types.includes("neighborhood") ||
                    x.types.includes("locality") ||
                    (x.types.includes("administrative_area_level_2") &&
                        fData["city"] == "")
                ) {
                    fData["city"] = fData.city != "" ? fData.city : x.long_name;
                }

                if (x.types.includes("administrative_area_level_1")) {
                    fData["state"] = x.long_name;
                }

                if (x.types.includes("postal_code")) {
                    fData["zipCode"] = x.long_name;
                }

                availableAddComponents.push(...x.types);
                formik.setFieldValue("zipCode", (fData.zipCode));
                formik.setFieldValue("city", fData.city);
                formik.setFieldValue("state", fData.state);
                formik.setFieldValue("address", fData.address);
                formik.setFieldValue("lat", places?.geometry.location.lat());
                formik.setFieldValue("lng", places?.geometry.location.lng());
                formik.setFieldError("address", "");
                formik.setFieldError("state", "");
                formik.setFieldError("zipCode", "");
            })
        }
    }
    const { touched, errors } = formik

    return (
        <>
            <LoadScript
                googleMapsApiKey={REACT_APP_GOOGLE_API_KEY}
                libraries={["places"]}
            >
                <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={onPlaceChanged}
                >
                    <input
                        className={
                            "form-control" +
                            (errors.address && touched.address
                            ? " is-invalid"
                            : "")
                        }
                        fullWidth
                        label="Address *"
                        placeholder="Enter Address"
                        value={formik.values.address}
                        onChange={e => {
                            formik.setFieldValue("address", e?.target.value)
                        }}
                        error={Boolean(Boolean(touched.address && errors.address))}
                    />
                </Autocomplete>
            </LoadScript>
        </>
    )
}

export default GoogleAutocompleteComp
