import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const tostS = (msg) => {
  var toastId = null;
  var customToastId = 1234;
  if (toast.isActive(toastId)) {
    toastId = toast.success(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
    });
  }

  toast.success(msg, {
    toastId: customToastId,
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
  });
};

export const tostE = (msg) => {
  var toastId = null;
  var customToastId = 1234;
  if (toast.isActive(toastId)) {
    toastId = toast.error(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      
    });
  }

  toast.error(msg, {
    toastId: customToastId,
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
  });
};

export const tostW = (msg) => {  
  var toastId = null;
  var customToastId = 1234;
  if (toast.isActive(toastId)) {
    toastId = toast.warn(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
    });
  }

  toast.warn(msg, {
    toastId: customToastId,
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
  });
};
