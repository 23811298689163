import React, { useEffect, useState } from 'react'
import { getUserTerms } from '../../Services/CommonServices'
import HtmlParser from 'react-html-parser'

export default function TermsAndConditions() {
    const [data, setData] = useState({})

    useEffect(() => {
      getUserTerms().then((res)=>{
        if(res.status==200){
            console.log(res.data.data)
            setData(res.data.data)
        }
      }).catch((err)=>{ 
        console.log(err)
      })    
    }, [])
    
    return (
        <div className='common-wrapper'>
            <div className='card common-content-card box-shadow'>
                {HtmlParser(data?.description)}
            </div>
        </div>
    )
}
