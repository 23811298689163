import { Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from '../Layout/DashboardLayout';
import DashboardScreen from '../Screens/Main/Dashboard';
import MyCoupon from '../Screens/Main/MyCoupons';
import Support from '../Screens/Main/Support';
import BusinessConfig from '../Screens/Main/BusinessConfig.js';
import SettingScreen from '../Screens/Settings/index.js';
import MyAccount from '../Screens/MyAccount/index.js';

// ----------------------------------------------------------------------

export default function DashBoardRoutes() {
  return useRoutes([
    {
      path: '/merchant',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard', element:<DashboardScreen />},        
        { path: 'my-coupon/:tab/:page/:id?', element:<MyCoupon />},        
        { path: 'support', element:<Support />},        
        { path: 'business-config/:page', element:<BusinessConfig />},        
        { path: 'my-account/:tab/:page?', element:<MyAccount />},        
        { path: 'settings', element:<SettingScreen />},        
        { path: '/merchant', element: <Navigate to="/merchant/dashboard" replace /> },
        { path: '*', element: <Navigate to="/merchant/dashboard" replace /> },
      ]
    },
    { path: '*', element: <Navigate to="/merchant/dashboard" replace /> }
  ]);
}
